import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Components
import SideNav      from 'components/layout/SideNav';
import TopNav       from 'components/layout/TopNav';

//-------------------------------------------------------------------------------------------------------------------

class Layout extends Component {

    render() {
        return (<>

            <TopNav {...this.props} />
            
            <main className="container">

                <SideNav {...this.props} extraContent={this.props.sideNavContent} />
                    
                <div className="main-content">

                    {this.props.children}

                </div>

            </main>

        </>);
    }
}

export default withRouter(Layout);