// Libs
import React from 'react';
import { Link } from 'react-router-dom';

// Services & Helpers
import API from 'API';

// Components
import Loader from 'components/common/Loader';

class ListUsers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
        window.scrollTo(0, 0);
    }

    componentDidUpdate(oldProps) {
        if (this.props.isApproved != oldProps.isApproved) {
            this.load();
        }
    }

    async load() {
        const { isApproved } = this.props;
        this.setState({
            isLoading: true
        });
        const users = await API.call('user/list', { isApproved });
        this.setState({
            isLoading: false,
            users
        });
    }

    view(id) {
        const { isApproved } = this.props;
        this.props.history.push(`/admin/${isApproved ? 'approved' : 'pending'}-user/${id || 0}`);
    }

    render() {
        const { isApproved } = this.props;

        return (
            <section>

                <h2>{isApproved ? 'Approved' : 'Pending'} users</h2>

                {this.renderInner()}

                <div className="control-panel">

                    <Link to="/admin/approved-user/0" className="btn btn-primary">
                        <span className="fa-solid fa-plus" />{' '}
                        New User
                    </Link>

                    <a href={`/api/user/export-csv/${isApproved ? 'approved' : 'pending'}`} className="btn btn-secondary ms-auto" target="_blank">
                        <span className="fa-solid fa-file-export" />{' '}
                        Export list to CSV
                    </a>

                </div>

            </section>
        );
    }

    renderInner() {
        const {
            isLoading,
            users
        } = this.state;
        const { isApproved } = this.props;

        if (isLoading) {
            return (<Loader />);
        }

        return (
            <table className="users-table clickable">
                <thead>
                    <tr>
                        <th className="name-col">Name</th>
                        <th className="company-col">Company</th>
                        <th className="email-col">Email</th>
                        {isApproved &&
                            <th className="admin-col">Admin</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {users.map(user =>
                        <tr key={user.id} onClick={() => this.view(user.id)}>
                            <td className="name-col">{user.name}</td>
                            <td className="company-col">{user.company}</td>
                            <td className="email-col">{user.email}</td>
                            {isApproved &&
                                <td className="admin-col">{user.isAdmin && <span className="fa-solid fa-check" />}</td>
                            }
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
}

export default ListUsers;
