// Libs
import React from 'react';

// Services & Helpers
import API from 'API';

// Components
import Loader from 'components/common/Loader';
import UploadButton from 'components/common/UploadButton';

class ImportProducts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //isLoading: true
        };
    }

    async uploadFile(file) {
        this.file = file;
        this.setState({
            isLoading: true,
            isImported: false
        });
        const { importRows, productFields } = await API.postFile('import', file, {
            commit: false
        });
        this.setState({
            isLoading: false,
            importRows,
            productFields: productFields.filter(pf => pf.hasData)
        });
    }

    async confirm() {
        this.setState({ isLoading: true });
        const { importRows, productFields } = await API.postFile('import', this.file, {
            commit: true
        });
        this.setState({
            isLoading: false,
            isImported: true,
            importRows,
            productFields
        });
    }

    //----------------------------------------------------------------------------------

    render() {
        return (
            <section className="import-products">

                <h2>Import Products</h2>

                {this.renderInner()}

            </section>
        );
    }

    renderInner() {
        const {
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <UploadButton
                resetOnUpload={false}
                onUpload={file => this.uploadFile(file)}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />

            {this.renderImportRows()}

        </>);
    }

    renderImportRows() {
        const { importRows, productFields, isImported } = this.state;
        if (!importRows) {
            return null;
        }
        if (importRows.length == 0) {
            return (
                <div className="empty-text">
                    Import file contains no products
                </div>
            );
        }
        if (isImported) {
            return (
                <div className="mt-3">
                    <span className="fa-solid fa-check" />{' '}
                    Products imported
                </div>
            );
        } 
        
        return (<>

            <div className="mt-3">

                <button className="btn btn-primary" onClick={() => this.confirm()}>
                    Confirm Import ({importRows.length} product(s))
                </button>

            </div>

            <div className="scrolling-table mt-3">
                <table className="table table-bordered import-products-table">
                    <thead>
                        <tr>
                            {productFields.map(pf =>
                                <th key={pf.name}>
                                    {pf.friendlyName || pf.name}
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {importRows.map((ir, index) =>
                            <tr key={index}>
                                {productFields.map(pf => {
                                    const value = ir[pf.name];
                                    return (
                                        <td key={pf.name} title={value}>
                                            {value ? JSON.stringify(value) : ''}
                                        </td>
                                    );
                                })}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>);
    }
}

export default ImportProducts;
