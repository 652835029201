// Libs
import React from 'react';

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (<>
             
        </>);
    }
}

export default Home;
