import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

// Services
import API from 'API';

//-------------------------------------------------------------------------------------------------------------------

class SideNav extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    async confirmLogOut() {
        const confirm = window.confirm('Are you sure you want to log out?');
        if (confirm) {
            await API.call('account/log-out');
            window.location = '/';
        }
    }

    componentDidMount() {
        this.loadProducts();
    }

    componentDidUpdate(oldProps) {
        if (this.props.supplierSlug != oldProps.supplierSlug && !this.props.loginInfo.site.sideNavFlatList) {
            this.loadProducts();
        }
    }

    async loadProducts() {
        const { supplierSlug, loginInfo } = this.props;
        let products = null;
        if (supplierSlug || loginInfo.site.sideNavFlatList) {
            this.setState({
                isLoadingProducts: true
            });
            const result = await API.call('product/list', {
                supplierSlug: (loginInfo.site.sideNavFlatList ? null : supplierSlug)
            });
            products = result.products;
        }
        this.setState({
            isLoadingProducts: false,
            products
        });
    }

    render() {
        const {
            isAdmin,
            supplierCategories,
            supplierCategorySlug,
            productSlug,
            loginInfo,
            extraContent
        } = this.props;
        const { isLoadingProducts, products } = this.state;

        if (isAdmin) {
            return this.renderAdmin();
        }

        let content = null;
        if (loginInfo.site.sideNavFlatList) {
            if (!isLoadingProducts && products) {
                content = (
                    <ul>
                        {products.map(product => {
                            const isActive = (productSlug == product.slug);
                            return (
                                <li key={product.id} className={isActive ? 'active' : ''}>
                                    <Link to={`/browse/${product.supplier.category.slug || '_'}/${product.supplier.slug || '_'}/${product.slug}`} onClick={() => window.scrollTo(0, 0)}>
                                        {product.name}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                );
            }
        } else {
            content = (
                <ul>
                    {supplierCategories.map(supplierCategory => {
                        const isActive = (supplierCategory.slug == supplierCategorySlug);
                        return (
                            <li key={supplierCategory.id} className={isActive ? 'active' : ''}>
                                <Link to={`/browse/${supplierCategory.slug}`}>
                                    {supplierCategory.name}
                                    <span className={`icon fa-solid ${isActive ? 'fa-chevron-down' : 'fa-chevron-right'}`} />
                                </Link>
                                {isActive && this.renderSuppliers(supplierCategory)}
                            </li>
                        );
                    })}
                </ul>
            );
        }

        return (
            <aside className="side-nav">
                {extraContent || null}
                {content}
            </aside>
        );
    }

    renderSuppliers(supplierCategory) {
        const {
            supplierSlug
        } = this.props;

        return (
            <ul>
                {supplierCategory.suppliers.map(supplier => {
                    const isActive = (supplier.slug == supplierSlug);
                    
                    return (
                        <li key={supplier.id}>
                            <Link to={`/browse/${supplierCategory.slug}/${supplier.slug}`} className={isActive ? 'active' : ''} onClick={() => window.scrollTo(0, 0)}>
                                {supplier.name}
                                <span className={`icon fa-solid ${isActive ? 'fa-chevron-down' : 'fa-chevron-right'}`} />
                            </Link>
                            {isActive && this.renderProducts(supplierCategory, supplier)}
                        </li>
                    );
                })}
            </ul>
        );
    }

    renderProducts(supplierCategory, supplier) {
        const {
            isLoadingProducts,
            products
        } = this.state;

        if (isLoadingProducts || !products) {
            return null;
        }

        return (
            <ul>
                {products.map(product =>
                    <li key={product.id}>
                        <Link to={`/browse/${supplierCategory.slug}/${supplier.slug}/${product.slug}`} onClick={() => window.scrollTo(0, 0)}>
                            {product.name}
                        </Link>
                    </li>
                )}
            </ul>
        );
    }

    renderAdmin() {
        const { page, extraContent } = this.props;

        return (
            <aside className="side-nav">
                {extraContent || null}

                <ul>
                    <li className={page == 'supplier-categories' || page == 'supplier-category' ? 'active' : ''}>
                        <Link to="/admin/supplier-categories">
                            Supplier Categories
                        </Link>
                    </li>
                    <li className={(page == 'suppliers' || page == 'supplier') ? 'active' : ''}>
                        <Link to="/admin/suppliers">
                            Suppliers
                        </Link>
                    </li>
                    <li className={(page == 'products' || page == 'product') ? 'active' : ''}>
                        <Link to="/admin/products">
                            Products
                        </Link>
                    </li>
                    <li className={(page == 'export-templates' || page == 'export-template') ? 'active' : ''}>
                        <Link to="/admin/export-templates">
                            Export Templates
                        </Link>
                    </li>
                    <li className={(page == 'approved-users' || page == 'approved-user') ? 'active' : ''}>
                        <Link to="/admin/approved-users">
                            Approved Users
                        </Link>
                    </li>
                    <li className={(page == 'pending-users' || page == 'pending-user') ? 'active' : ''}>
                        <Link to="/admin/pending-users">
                            Pending Users
                        </Link>
                    </li>
                </ul>
            </aside>
        );
    }

}

export default withRouter(SideNav);
