// Libs
import React from 'react';
import ExpandingTextArea from 'react-expanding-textarea';

// Services & Helpers
import API from 'API';
import TextHelpers from 'helpers/TextHelpers';

// Components
import Loader from 'components/common/Loader';

class EditSupplier extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { id } = this.props;
        this.setState({
            isLoading: true
        });
        let supplier;
        const categories = await API.call('supplier-category/list');
        if (parseInt(id)) {
            supplier = await API.call('supplier/get', {
                id: (id || 0)
            });
        } else {
            supplier = {
                supplierCategoryID: (categories.length > 0 ? categories[0].id : null)
            };
        }
        this.setState({
            isLoading: false,
            supplier,
            categories
        });
    }

    updateFields(values) {
        const supplier = { ...this.state.supplier };
        for (let fieldName in values) {
            const value = values[fieldName];
            supplier[fieldName] = value;
            if (fieldName == 'name' && !supplier.id) {
                supplier.slug = TextHelpers.getSlug(value);
            }
        }
        this.setState({ supplier });
    }

    async save() {
        const { supplier } = this.state;
        this.setState({ isLoading: true });
        await API.call('supplier/save', supplier);
        this.props.history.replace('/admin/suppliers');
    }

    confirmDelete() {
        const confirm = window.confirm('Are you sure you want to delete this supplier?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        this.setState({ isLoading: true });
        await API.call('supplier/delete/' + this.state.supplier.id);
        this.props.history.replace('/admin/suppliers');
    }

    render() {
        return (
            <section>

                <h2>Supplier</h2>

                {this.renderInner()}

            </section>
        );
    }

    renderInner() {
        const {
            isLoading,
            supplier,
            categories
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (

            <form onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                this.save();
            }}>

                <div className="form-group">
                    <label className="form-label">Name</label>
                    <input
                        type="text"
                        className="form-control"
                        value={supplier.name || ''}
                        onChange={e => this.updateFields({ name: e.target.value })}
                    />
                </div>

                <div className="form-group">
                    <label className="form-label">Slug</label>
                    <input
                        type="text"
                        className="form-control"
                        value={supplier.slug || ''}
                        onChange={e => this.updateFields({ slug: e.target.value })}
                    />
                </div>

                <div className="form-group">
                    <label className="form-label">Category</label>
                    <select
                        className="form-control"
                        value={supplier.supplierCategoryID || ''}
                        onChange={e => this.updateFields({ supplierCategoryID: e.target.value })}
                    >
                        {categories.map(category =>
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        )}
                    </select>
                </div>

                <div className="form-group">
                    <label className="form-label">Inventory Location</label>
                    <input
                        type="text"
                        className="form-control"
                        value={supplier.inventoryLocation || ''}
                        onChange={e => this.updateFields({ inventoryLocation: e.target.value })}
                    />
                </div>

                <div className="form-group">
                    <label className="form-label">Contact Details</label>
                    <ExpandingTextArea
                        className="form-control"
                        value={supplier.contactDetails || ''}
                        onChange={e => this.updateFields({ contactDetails: e.target.value })}
                    />
                </div>

                <div className="form-group">
                    <label className="form-label">Supplier Information</label>
                    <ExpandingTextArea
                        className="form-control"
                        value={supplier.information || ''}
                        onChange={e => this.updateFields({ information: e.target.value })}
                    />
                </div>

                <div className="control-panel">

                    {!!supplier.id &&
                        <button type="button" className="btn btn-secondary" onClick={() => this.confirmDelete()}>
                            <span className="fa-solid fa-times" />{' '}
                            Delete
                        </button>
                    }

                    <button type="submit" className="btn btn-primary ms-auto">
                        <span className="fa-solid fa-check" />{' '}
                        Save
                    </button>

                </div>

            </form>

        );
    }
}

export default EditSupplier;
