// Libs
import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router';
 
//  Services
import API from 'API';

// Components
import Layout from 'components/layout/Layout';
import Loader from 'components/common/Loader';
import Login from 'components/Login';

// Components - pages
import MiAHome from 'components/MiAHome';
import DisruptHome from 'components/DisruptHome';
import DisruptSideNav from 'components/DisruptSideNav';
import ViewProduct from 'components/product/ViewProduct';
import ViewSupplier from 'components/supplier/ViewSupplier';
import AdminHome from 'components/admin/Home';
import AdminListSupplierCategories from 'components/admin/ListSupplierCategories';
import AdminEditSupplierCategory from 'components/admin/EditSupplierCategory';
import AdminListSuppliers from 'components/admin/ListSuppliers';
import AdminEditSupplier from 'components/admin/EditSupplier';
import AdminListUsers from 'components/admin/ListUsers';
import AdminEditUser from 'components/admin/EditUser';
import AdminListProducts from 'components/admin/ListProducts';
import AdminListExportTemplates from 'components/admin/ListExportTemplates';
import AdminEditExportTemplate from 'components/admin/EditExportTemplate';
import AdminEditProduct from 'components/admin/EditProduct';
import AdminImportProducts from 'components/admin/ImportProducts';

//-------------------------------------------------------------------------------------------------------------------

class App extends Component {

    constructor(props) {
        super(props);

        this.setTitle = this.setTitle.bind(this);

        this.state = {
            isLoading: true,
            loginInfo: null
        };

        this.checkLogin = this.checkLogin.bind(this);
    }

    async componentDidMount() {
        this.checkLogin();
    }

    async checkLogin() {
        // Determine if logged in
        let loginInfo = null;
        try {
            loginInfo = await API.call('account/get-login-info');
        } catch (e) {
            // Ignore error - loginInfo will be null
        }

        // Set up site-level UI
        document.body.classList.add(loginInfo.site.cssClass);
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = `/${loginInfo.site.cssClass}-favicon.png`;
        document.getElementsByTagName('head')[0].appendChild(link);

        // If logged in, load more info
        let supplierCategories;
        if (loginInfo.user) {
            supplierCategories = await API.call('supplier/list-by-category');
        }

        // Update UI
        this.setState({
            loginInfo,
            supplierCategories,
            isLoading: false
        }, () => {
            this.setTitle('');
        });
    }

    setTitle(page) {
        const { loginInfo } = this.state;
        const parts = [
            page
        ].filter(p => !!p);
        if (parts.length == 0) {
            parts.push(loginInfo.site.name);
        }
        document.title = parts.join(' | ');
    }

    //-------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            loginInfo
        } = this.state;
        const rootNode = document.querySelector('#root');

        // Loading login status
        if (isLoading) {
            return (<Loader />);
        }

        // Not logged in yet
        if (!loginInfo || !loginInfo.user) {
            rootNode.classList.add('not-logged-in');
            return this.renderNotLoggedIn();
        }

        // Logged in
        rootNode.classList.remove('not-logged-in');
        return this.renderLoggedIn();
    }

    renderNotLoggedIn() {
        const { loginInfo } = this.state;
        return (
            <Login
                loginInfo={loginInfo}
                onLogIn={this.checkLogin}
            />
        );
    }

    renderLoggedIn() {
        const {
            loginInfo,
            supplierCategories
        } = this.state;

        // Select site-specific components
        let HomeComponent, SideNavComponent = null;
        switch (loginInfo.site.cssClass) {
            case 'mia':
                HomeComponent = MiAHome;
                break;
            case 'disrupt':
                HomeComponent = DisruptHome;
                SideNavComponent = DisruptSideNav;
                break;
        }

        const getChildProps = (props) => ({
            ...props.match.params,
            history: props.history,
            loginInfo,
            supplierCategories,
            sideNavContent: (SideNavComponent ? <><SideNavComponent /></> : null),
            fns: {
                setTitle: this.setTitle
            }
        });

        return (
            <Switch>

                {/* Browse */}
                <Route path="/browse/:supplierCategorySlug?/:supplierSlug?/:productSlug?" render={(props) => {
                    const childProps = getChildProps(props);
                    return (
                        <Layout {...childProps}>
                            {
                                childProps.productSlug ? <ViewProduct {...childProps} /> :
                                childProps.supplierSlug ? <ViewSupplier {...childProps } /> :
                                <HomeComponent {...childProps} />
                            }
                        </Layout>
                    );
                }} />

                {/* Admin */}
                <Route path="/admin/:page?" render={(props) => {
                    const childProps = getChildProps(props);
                    return (
                        <Layout {...childProps} isAdmin>
                            <Switch>
                                <Route path="/admin/supplier-categories" component={AdminListSupplierCategories} />
                                <Route path="/admin/supplier-category/:id?" render={(props) => {
                                    const adminChildProps = { ...childProps, ...props.match.params };
                                    return (
                                        <AdminEditSupplierCategory {...adminChildProps} />
                                    );
                                }} />
                                <Route path="/admin/suppliers" component={AdminListSuppliers} />
                                <Route path="/admin/supplier/:id?" render={(props) => {
                                    const adminChildProps = { ...childProps, ...props.match.params };
                                    return (
                                        <AdminEditSupplier {...adminChildProps} />
                                    );
                                }} />
                                <Route path="/admin/products/:page?" render={(props) => {
                                    const adminChildProps = { ...childProps, ...props.match.params };
                                    return (
                                        <AdminListProducts {...adminChildProps} />
                                    );
                                }} />
                                <Route path="/admin/product/:id?" render={(props) => {
                                    const adminChildProps = { ...childProps, ...props.match.params };
                                    return (
                                        <AdminEditProduct {...adminChildProps} />
                                    );
                                }} />
                                <Route path="/admin/export-templates" render={(props) => {
                                    const adminChildProps = { ...childProps, ...props.match.params };
                                    return (
                                        <AdminListExportTemplates {...adminChildProps} />
                                    );
                                }} />
                                <Route path="/admin/export-template/:id?" render={(props) => {
                                    const adminChildProps = { ...childProps, ...props.match.params };
                                    return (
                                        <AdminEditExportTemplate {...adminChildProps} />
                                    );
                                }} />

                                <Route path="/admin/approved-users" render={(props) => {
                                    const adminChildProps = { ...childProps, ...props.match.params };
                                    return (
                                        <AdminListUsers {...adminChildProps} isApproved />
                                    );
                                }} />
                                <Route path="/admin/approved-user/:id?" render={(props) => {
                                    const adminChildProps = { ...childProps, ...props.match.params };
                                    return (
                                        <AdminEditUser {...adminChildProps} />
                                    );
                                }} />
                                <Route path="/admin/pending-users" render={(props) => {
                                    const adminChildProps = { ...childProps, ...props.match.params };
                                    return (
                                        <AdminListUsers {...adminChildProps} isApproved={false} />
                                    );
                                }} />
                                <Route path="/admin/pending-user/:id?" render={(props) => {
                                    const adminChildProps = { ...childProps, ...props.match.params };
                                    return (
                                        <AdminEditUser {...adminChildProps} />
                                    );
                                }} />
                                <Route path="/admin/import-products" component={AdminImportProducts} />
                                <Route path="/admin" exact Component={AdminHome} />
                            </Switch>
                        </Layout>
                    );
                }} />

                {/* Home */}
                <Route path="/" render={(props) => {
                    const childProps = getChildProps(props);
                    return (
                        <Layout {...childProps}>
                            <HomeComponent {...getChildProps(props)} />
                        </Layout>
                    );
                }} />
            </Switch>
        );
    }
}

export default withRouter(App);
