import React from 'react';
import propTypes from 'prop-types';

//-------------------------------------------------------------------------------------------------------------------

const Loader = (props) => {
    return (
        <div className={'spinner-border ' + (props.isInline ? 'inline' : '') + ' ' + (props.className || '')}>
            <span className="sr-only">Loading...</span>
        </div>
    );
}
export default Loader;