// Libs
import React from 'react';
//import Slider from 'react-slick';
//import 'slick-carousel/slick/slick.css';
//import 'slick-carousel/slick/slick-theme.css';
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import slide1 from 'images/slide-1.webp';
import slide2 from 'images/slide-2.webp';
import slide3 from 'images/slide-3.webp';
import slide4 from 'images/slide-4.webp';
import slide5 from 'images/slide-5.webp';

class DisruptHome extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className='home-page'>
                {this.renderCarousel()}

                <h2 style={{fontSize: '20px'}}>Disrupt Sports Product Database</h2>

                <p>Welcome to the Disrupt Sports Product Database platform!</p>
                <p>Built for our employees and representatives, here, you can explore our extensive product catalog,
                    access essential information and generate your own Quote PDFs.</p>

                <h3 style={{fontSize: '16px'}}>🔍 Searching Products:</h3>

                <p>
                    Our search function allows you to quickly find details about our product range. You can search by
                    the name or quality specified for each item in the database. The search results will be displayed as
                    a list of categories containing all relevant items. Please note that searching is not
                    case-sensitive, and it does not support boolean operators such as 'and', 'or', 'not'.
                </p>

                <h3 style={{fontSize: '16px'}}>💰 Generating Quotes:</h3>

                <p>
                    When viewing a product page, simply click on "Generate Quote" and a downloadable PDF will be
                    generated containing comprehensive details about the product, along with pricing for various
                    quantities.
                </p>

                <h3 style={{fontSize: '16px'}}>📈 Database Roadmap (Coming Soon):</h3>

                <p>We're committed to continuously improving our database to meet your needs. Here’s what we are
                    currently working on:</p>
                <ul>
                    <li>Bulk Pricing Options: Whether you need a large quantity of a particular product or you're
                        looking for volume discounts, we're enhancing our pricing options to cater to higher quantities
                        across all our products.
                    </li>
                    <li>New Product Additions: We're dedicated to expanding our product offerings by continually adding
                        new items to the database.
                    </li>
                    <li>Consolidated Product Specifications: We understand the importance of having comprehensive
                        information at your fingertips. That's why we're consolidating the specifications of each
                        product, ensuring you have all the details you need to make informed decisions.
                    </li>
                </ul>

                <p>If you have any suggestions on how we can improve the database further, please email <a href="mailto:matt@disruptsports.com">matt@disruptsports.com</a> with your suggestions.</p>

                <h3 style={{fontSize: '16px'}}>💬 Need More Information?</h3>

                <p>If there's any product information missing or if you require further assistance, please don't
                    hesitate to reach out to Veronica at <a
                        href="mailto:veronica@disruptsports.com">veronica@disruptsports.com</a>.</p>
                <p>Your feedback is valuable to us, and we're here to help you make the most out of our platform.</p>
                <p>Thank you for being a part of the Disrupt Sports community!</p>
            </div>
        );
    }

    renderCarousel() {
        const settings = {
            autoPlay: true,
            showArrows: true,
            showStatus: false,
            infiniteLoop: true
        };
        return (
            <Carousel {...settings}>
                <div style={{backgroundImage: `url(${slide1})`}}>
                    <div className="slider-text">
                        <p>THE HOME OF <br/>BRANDED SPORTS <br/>EQUIPMENT</p>
                        <p className="subtitle">Your Brand. Your Way.</p>
                    </div>
                </div>
                <div style={{backgroundImage: `url(${slide2})`}}></div>
                <div style={{backgroundImage: `url(${slide3})`}}></div>
                <div style={{backgroundImage: `url(${slide4})`}}></div>
                <div style={{backgroundImage: `url(${slide5})`}}></div>
            </Carousel>
        );
    }
}

export default DisruptHome;
