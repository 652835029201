import React from 'react';
import Modal from 'react-bootstrap/Modal';

// Services
import API from 'API';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class QuoteModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            message: 'Valid for 14 days',
            currencyIDs: {}
        };
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                id: opt.id
            }, () => {
                this.load();
            });
        });
    }

    async load() {
        const {loginInfo} = this.props;
        let currencies = await API.call('currency/list');
        // if (loginInfo.user.defaultCurrencyID) {
        //     currencies = currencies.filter(c => c.id === loginInfo.user.defaultCurrencyID);
        // }
        const currencyIDs = {};
        currencies.forEach((c, index) => {
            currencyIDs[c.id] = c.name === 'US Dollar';
        });
        this.setState({
            currencies,
            currencyIDs,
            isLoading: false
        });
    }

    close() {
        this.setState({isOpen: false});
    }

    toggleCurrency(id) {
        this.setState({currencyIDs: {[id]: true}});
    }

    async generateQuote() {
        const {id, currencyIDs, rebateAmount} = this.state;
        console.log(currencyIDs);
        const selectedCurrencyId = Object.keys(currencyIDs).find(key => currencyIDs[key]);
        console.log(selectedCurrencyId);
        const message = encodeURIComponent(this.state.message);
        window.open(`/api/product/get-quote/${id}?currencyIDs=${selectedCurrencyId}&message=${message}&rebateAmount=${rebateAmount || 0}`);
    }

    render() {
        const {
            isOpen,
            isLoading
        } = this.state;

        if (!isOpen) {
            return null;
        }

        const {loginInfo} = this.props;

        if (!loginInfo.user.isAdmin && loginInfo.user.defaultCurrencyID) {
            this.generateQuote();
            this.close();
        }

        return (<>
                { ((!loginInfo.user.isAdmin && loginInfo.user.defaultCurrencyID) || loginInfo.user.isAdmin) &&
                <Modal show onHide={() => this.close()} className="quote-modal" backdrop="static" keyboard={false}>
                    {isLoading ? <Modal.Body><Loader/></Modal.Body> :
                        <form onSubmit={e => {
                            this.generateQuote();
                            e.preventDefault()
                        }}>
                            <Modal.Body>
                                {this.renderInner()}
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="button" className="btn btn-secondary" onClick={() => this.close()}>
                                    Close
                                </button>
                                <button type="submit" className="btn btn-primary ms-auto">
                                    Generate Quote
                                </button>
                            </Modal.Footer>
                        </form>
                    }
                </Modal>}
            </>
        );
    }

    renderInner() {
        const {loginInfo} = this.props;
        const {message, currencies, currencyIDs, rebateAmount} = this.state;

        return (<>

            {(!loginInfo.user.defaultCurrencyID || loginInfo.user.isAdmin) && <div className="mb-3">
                {currencies.map(c =>
                    <div className="form-check" key={c.id}>
                        <input
                            id={`currency-${c.id}`}
                            type="radio"
                            className="form-check-input"
                            checked={currencyIDs[c.id] || false}
                            onChange={e => this.toggleCurrency(c.id)}
                        />
                        <label className="form-check-label" htmlFor={`currency-${c.id}`}>
                            Quote in {c.isoCode} ({c.symbol})
                        </label>
                    </div>
                )}
            </div>
            }

            <div className="form-group">
                <label className="form-label">Rebate Amount (%)</label>
                <input
                    className="form-control"
                    type="number"
                    key={rebateAmount || ''}
                    defaultValue={rebateAmount || ''}
                    disabled={!loginInfo.user.isAdmin}
                    onBlur={e => this.setState({rebateAmount: e.target.value})}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Message</label>
                <textarea
                    className="form-control"
                    key={message || ''}
                    defaultValue={message || ''}
                    disabled={true}
                    onBlur={e => this.setState({message: e.target.value})}
                />
            </div>

        </>);
    }
}

export default QuoteModal;