// Libs
import React from 'react';

class DisruptSideNav extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="phone-numbers">
                <div className="phone-number">
                    <img src="//cdn.shopify.com/s/files/1/0284/1529/5533/t/2/assets/Aus-flag_15x.png?v=16869283047483579461" />
                    <a href="tel:+610280035259">+61 02 8003 5259</a>
                </div>
                <div className="phone-number">
                    <img src="//cdn.shopify.com/s/files/1/0284/1529/5533/t/2/assets/US-Flag_15x.png?v=3015067926451577879" />
                    <a href="tel:+14242299300">+1 424 229 9300</a>
                </div>
                <div className="phone-number">
                    <img src="//cdn.shopify.com/s/files/1/0284/1529/5533/t/2/assets/UK-Flag_15x.png?v=6277544244467972645" />
                    <a href="tel:+442032870310">+44 20 3287 0310</a>
                </div>
            </div>
        );
    }
}

export default DisruptSideNav;
