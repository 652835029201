import React from 'react';

export default class TextHelpers {

    static simplifySearchString(str) {
        return (str || '').toLowerCase().replace(/[^a-z0-9 -'&@]/, '').trim();
    }

    static formatCurrency(amount, opt) {
        const formatter = new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
            currencyDisplay: 'narrowSymbol',
            ...opt
        });
        let str = formatter.format(amount);
        str = str.replace('.00', '');
        return str;
    }

    static buildURL(url, queryString) {
        let isFirstQS = true;
        if (queryString) {
            for (var key in queryString) {
                let value = queryString[key];
                if (value) {
                    url += (isFirstQS ? '?' : '&') + key + '=' + encodeURIComponent(value);
                    isFirstQS = false;
                }
            }
        }
        return url;
    }

    static escapeHTML(html) {
        html = html.replace(/&/g, '&amp;');
        html = html.replace(/>/g, '&gt;');
        html = html.replace(/</g, '&lt;');
        return html;
    }

    static escapeJSString(str) {
        str = str || '';
        str = str.replace(/'/g, '\\\'');
        str = str.replace(/\r/g, '\\r');
        str = str.replace(/\n/g, '\\n');
        return str;
    }

    static escapeAttribute(str) {
        str = str || '';
        str = str.replace(/"/g, '&quot;');
        str = str.replace(/\\/g, '\\\\');
        return str;
    }

    static getSlug(str) {
        str = str || '';
        str = str.toLowerCase();
        str = str.replace(/[^a-z0-9\-]/gi, '-');
        str = str.replace(/-{2,}/gi, '-');
        str = str.replace(/-$/gi, '');
        return str;
    }

    static splitIntoRows(str) {
        return (str || '').split(/\r\n|\r|\n/).map((row, i) =>
            <div key={i}>{row}</div>
        );
    }

    static getRandomID() {
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';
        for (let i = 0; i < 16; i++) {
            result += chars[Math.floor(Math.random() * chars.length)];
        }
        return result;
    }
}

window.TextHelpers = TextHelpers;
