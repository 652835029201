// Libs
import React from 'react';

import logoImage from 'images/mia-logo.png';
import homeImage from 'images/mia-home.jpg';

class MiAHome extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (<>

            <div style={{ display: 'flex' }}>

                <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

                    <img src={logoImage} />
                    <p style={{ marginTop: 10, fontWeight: 'bold', lineHeight: '200%' }}>
                        Find products using the search
                        bar or navigate the side bar via
                        location and suppliers
                    </p>

                </div>

                <div style={{ flex: 8 }}>

                    <img className="home-image" src={homeImage} />

                </div>

            </div>
             
        </>);
    }
}

export default MiAHome;
