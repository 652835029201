// Libs
import React from 'react';

// Services & Helpers
import API from 'API';

// Components
import Loader from 'components/common/Loader';

class EditExportTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { id } = this.props;
        this.setState({
            isLoading: true
        });
        let exportTemplate;
        if (parseInt(id)) {
            exportTemplate = await API.call('export-template/get/' + (id || 0));
        } else {
            exportTemplate = {
                format: 'CSV',
                productFields: []
            };
        }

        // Get product fields and group them
        const productFields = await API.call('product-field/list');
        const productFieldGroups = [];
        productFields.forEach(pf => {
            let groupIndex = productFieldGroups.findIndex(pfg => pfg.name == pf.groupName);
            if (groupIndex == -1) {
                groupIndex = productFieldGroups.length;
                productFieldGroups.push({
                    name: pf.groupName,
                    productFields: []
                });
            }
            productFieldGroups[groupIndex].productFields.push(pf);
        });
        productFieldGroups.sort((a, b) => (a.name > b.name ? 1 : (b.name > a.name ? -1 : 0)));
        productFieldGroups.forEach(pfg => {
            pfg.productFields.sort((a, b) => (a.name > b.name ? 1 : (b.name > a.name ? -1 : 0)));
        });
        
        this.setState({
            isLoading: false,
            exportTemplate,
            productFields,
            productFieldGroups
        });
    }

    updateFields(values) {
        const exportTemplate = { ...this.state.exportTemplate };
        for (let fieldName in values) {
            const value = values[fieldName];
            exportTemplate[fieldName] = value;
        }
        this.setState({ exportTemplate });
    }

    async save() {
        const { exportTemplate } = this.state;
        this.setState({ isLoading: true });
        await API.call('export-template/save', exportTemplate);
        this.props.history.replace('/admin/export-templates');
    }

    confirmDelete() {
        const confirm = window.confirm('Are you sure you want to delete this export template?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        this.setState({ isLoading: true });
        await API.call('export-template/delete/' + this.state.exportTemplate.id);
        this.props.history.replace('/admin/export-templates');
    }

    updateProductField(id, fields) {
        const productFields = [...this.state.exportTemplate.productFields];
        let index = productFields.findIndex(pf => pf.id == id);
        if (index == -1) {
            index = productFields.length;
            let lowestID = 0;
            let position = 0;
            productFields.forEach(pf => {
                if (pf.id < lowestID) {
                    lowestID = pf.id;
                }
                if (pf.position > position) {
                    position = pf.position;
                }
            });
            productFields.push({
                id: lowestID - 1,
                position: position + 1
            });
        }
        productFields[index] = { ...productFields[index] }
        for (let fieldName in fields) {
            const value = fields[fieldName];
            productFields[index][fieldName] = value;
        }
        this.updateFields({ productFields });
    }

    moveProductField(id, delta) {
        const productFields = [...this.state.exportTemplate.productFields];
        let index = productFields.findIndex(pf => pf.id == id);
        productFields[index] = { ...productFields[index] }
        productFields[index].position += delta;
        productFields[index + delta].position -= delta;
        productFields.sort((a, b) => a.position - b.position);
        this.updateFields({ productFields });
    }

    removeProductField(id) {
        const productFields = [...this.state.exportTemplate.productFields];
        let index = productFields.findIndex(pf => pf.id == id);
        productFields.splice(index, 1);
        this.updateFields({ productFields });
    }

    render() {
        return (
            <section>

                <h2>Export Template</h2>

                {this.renderInner()}

            </section>
        );
    }

    renderInner() {
        const {
            isLoading,
            exportTemplate
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (

            <form onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                this.save();
            }}>

                <div className="form-group">
                    <label className="form-label">Name</label>
                    <input
                        type="text"
                        className="form-control"
                        value={exportTemplate.name || ''}
                        onChange={e => this.updateFields({ name: e.target.value })}
                    />
                </div>

                <div className="form-group">
                    <label className="form-label">Format</label>
                    <select
                        className="form-control"
                        value={exportTemplate.format || ''}
                        onChange={e => this.updateFields({ format: e.target.value })}
                    >
                        <option value="CSV">CSV</option>
                        <option value="XLSX">XLSX</option>
                    </select>
                </div>

                {this.renderProductFields()}

                <div className="control-panel">

                    {!!exportTemplate.id &&
                        <button type="button" className="btn btn-secondary" onClick={() => this.confirmDelete()}>
                            <span className="fa-solid fa-times" />{' '}
                            Delete
                        </button>
                    }

                    <button type="submit" className="btn btn-primary ms-auto">
                        <span className="fa-solid fa-check" />{' '}
                        Save
                    </button>

                </div>

            </form>

        );
    }

    renderProductFields() {
        const { exportTemplate, productFieldGroups } = this.state;
        const exportTemplateProductFields = [...exportTemplate.productFields];
        if (!exportTemplateProductFields.find(pf => pf.id == 'new')) {
            let position = 0;
            exportTemplateProductFields.forEach(pf => {
                if (pf.position > position) {
                    position = pf.position;
                }
            });
            position++;
            exportTemplateProductFields.push({
                id: 'new',
                position
            });
        }
        return (
            <table className="table export-template-product-fields-table">
                <thead>
                    <tr>
                        <th className="position-col">#</th>
                        <th className="field-col">Field</th>
                        <th className="label-col">Label</th>
                        <th className="actions-col"></th>
                    </tr>
                </thead>
                <tbody>
                    {exportTemplateProductFields.map((pf, index) =>
                        <tr key={pf.id}>
                            <td className="position-col">
                                {pf.position}
                            </td>
                            <td className="field-col">
                                <select
                                    key={Math.random()}
                                    className="form-control"
                                    value={pf.productFieldID || ''}
                                    onChange={e => this.updateProductField(pf.id, { productFieldID: e.target.value })}
                                >
                                    <option value="">(Select)</option>
                                    {productFieldGroups.map(pfg =>
                                        <optgroup key={pfg.name} label={pfg.name}>
                                            {pfg.productFields.map(pf =>
                                                <option key={pf.id} value={pf.id}>
                                                    {pf.friendlyName || pf.name}
                                                </option>
                                            )}
                                        </optgroup>
                                    )}
                                </select>
                            </td>
                            <td className="label-col">

                                {pf.id != 'new' &&
                                    <input
                                        type="text"
                                        className="form-control"
                                        key={pf.label}
                                        defaultValue={pf.label}
                                        placeholder="Default"
                                        onBlur={e => this.updateProductField(pf.id, { label: e.target.value })}
                                    />
                                }
                                
                            </td>
                            <td className="actions-col">
                                {pf.id != 'new' && <>
                                    <button type="button" className="btn btn-secondary me-2" title="Move up" disabled={index == 0} onClick={() => this.moveProductField(pf.id, -1)}>
                                        <span className="fa-solid fa-chevron-up" />
                                    </button>

                                    <button type="button" className="btn btn-secondary me-2" title="Move down" disabled={index == exportTemplateProductFields.length - 2} onClick={() => this.moveProductField(pf.id, 1)}>
                                        <span className="fa-solid fa-chevron-down" />
                                    </button>

                                    <button type="button" className="btn btn-danger" title="Remove" onClick={() => this.removeProductField(pf.id)}>
                                        <span className="fa-solid fa-times" />
                                    </button>
                                </>}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
}

export default EditExportTemplate;
