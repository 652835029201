// Libs
import React from 'react';

// Services & Helpers
import API from 'API';
import TextHelpers from 'helpers/TextHelpers';

// Components
import Loader from 'components/common/Loader';

class EditSupplierCategory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { id } = this.props;
        this.setState({
            isLoading: true
        });
        const currencies = await API.call('currency/list');
        let category;
        if (parseInt(id)) {
            category = await API.call('supplier-category/get/' + (id || 0));
        } else {
            category = {};
        }
        this.setState({
            isLoading: false,
            category,
            currencies
        });
    }

    updateFields(values) {
        const category = { ...this.state.category };
        for (let fieldName in values) {
            const value = values[fieldName];
            category[fieldName] = value;
            if (fieldName == 'name' && !category.id) {
                category.slug = TextHelpers.getSlug(value);
            }
        }
        this.setState({ category });
    }

    async save() {
        const { category } = this.state;
        this.setState({ isLoading: true });
        await API.call('supplier-category/save', category);
        this.props.history.replace('/admin/supplier-categories');
    }

    confirmDelete() {
        const confirm = window.confirm('Are you sure you want to delete this category?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        this.setState({ isLoading: true });
        await API.call('supplier-category/delete/' + this.state.category.id);
        this.props.history.replace('/admin/supplier-categories');
    }

    render() {
        return (
            <section>

                <h2>Supplier Category</h2>

                {this.renderInner()}

            </section>
        );
    }

    renderInner() {
        const {
            isLoading,
            category,
            currencies
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (

            <form onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                this.save();
            }}>

                <div className="form-group">
                    <label className="form-label">Name</label>
                    <input
                        type="text"
                        className="form-control"
                        value={category.name || ''}
                        onChange={e => this.updateFields({ name: e.target.value })}
                    />
                </div>

                <div className="form-group">
                    <label className="form-label">Slug</label>
                    <input
                        type="text"
                        className="form-control"
                        value={category.slug || ''}
                        onChange={e => this.updateFields({ slug: e.target.value })}
                    />
                </div>
                <div className="form-group">
                    <label className="form-label">Currency</label>
                    <select
                        className="form-control"
                        value={category.currencyID || ''}
                        onChange={e => this.updateFields({ currencyID: e.target.value })}
                    >
                        <option value="">(Select)</option>
                        {currencies.map(currency =>
                            <option key={currency.id} value={currency.id}>
                                {currency.isoCode} - {currency.name}
                            </option>
                        )}
                    </select>
                </div>

                <div className="control-panel">

                    {!!category.id &&
                        <button type="button" className="btn btn-secondary" onClick={() => this.confirmDelete()}>
                            <span className="fa-solid fa-times" />{' '}
                            Delete
                        </button>
                    }

                    <button type="submit" className="btn btn-primary ms-auto">
                        <span className="fa-solid fa-check" />{' '}
                        Save
                    </button>

                </div>

            </form>

        );
    }
}

export default EditSupplierCategory;
