// Libs
import React from 'react';
import { Link } from 'react-router-dom';

// Services & Helpers
import API from 'API';

// Components
import Loader from 'components/common/Loader';

class ListExportTemplates extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        this.setState({
            isLoading: true
        });
        const exportTemplates = await API.call('export-template/list');
        this.setState({
            isLoading: false,
            exportTemplates
        });
    }

    view(id) {
        this.props.history.push(`/admin/export-template/${id || 0}`);
    }

    render() {
        return (
            <section>

                <h2>Export Templates</h2>

                {this.renderInner()}

                <div className="control-panel">

                    <Link to="/admin/export-template/0" className="btn btn-primary">
                        <span className="fa-solid fa-plus" />{' '}
                        New Export Template
                    </Link>

                </div>

            </section>
        );
    }

    renderInner() {
        const {
            isLoading,
            exportTemplates
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (

            <table className="exportTemplates-table clickable">
                <thead>
                    <tr>
                        <th className="name-col">Name</th>
                        <th className="fields-col">Fields</th>
                    </tr>
                </thead>
                <tbody>
                    {exportTemplates.map(exportTemplate =>
                        <tr key={exportTemplate.id} onClick={() => this.view(exportTemplate.id)}>
                            <td className="name-col">{exportTemplate.name}</td>
                            <td className="fields-col">
                                {exportTemplate.productFields.map(f => f.label || f.friendlyName || f.name).join(', ')}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

        );
    }
}

export default ListExportTemplates;
