// Libs
import React from 'react';
import ExpandingTextArea from 'react-expanding-textarea';

// Services & Helpers
import API from 'API';
import TextHelpers from 'helpers/TextHelpers';

// Components
import Loader from 'components/common/Loader';
import CurrencyInput from 'components/common/CurrencyInput';

class EditProductSKU extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            tab: 'product-type'
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const {  loginInfo } = this.props;
        this.setState({ isLoading: true });
        let currencies;
        if (loginInfo.site.usePricingMatrix) {
            currencies = await API.call('currency/list');
        }
        this.setState({
            isLoading: false,
            currencies
        });
    }

    updateFields(values) {
        this.props.onUpdate(values);
    }

    updatePhotoFields(index, values) {
        let photos = [...this.props.sku.photos];
        if (index == photos.length) {
            photos.push({
                id: -photos.length
            });
        }
        const photo = { ...photos[index] };
        for (let fieldName in values) {
            const value = values[fieldName];
            photo[fieldName] = value;
        }
        photos[index] = photo;
        photos = photos.filter(p => !!p.url);
        this.updateFields({ photos });
    }

    confirmDelete() {
        const confirm = window.confirm('Are you sure you want to delete this product?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        this.setState({ isLoading: true });
        await API.call('product/delete/' + this.state.product.id);
        this.props.history.replace('/admin/products');
    }

    updateQuantityFields(quantity, fields) {
        const quantities = [...this.props.sku.quantities];
        let index = quantities.findIndex(psq => psq.quantity == quantity);
        if (index == -1) {
            index = quantities.length;
            quantities.push({ quantity });
        }
        const quantityRow = { ...quantities[index] };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            quantityRow[fieldName] = value;
        }
        delete quantityRow.isNew;
        quantities[index] = quantityRow;
        this.updateFields({ quantities }); 
    }

    updatePriceFields(quantity, currencyID, fields) {
        const prices = [...this.props.sku.prices];
        let index = prices.findIndex(psp => psp.quantity == quantity && psp.currencyID == currencyID);
        if (index == -1) {
            index = prices.length;
            prices.push({
                quantity,
                currencyID
            });
        }
        const price = { ...prices[index] };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            price[fieldName] = value;
        }
        delete price.isNew;
        prices[index] = price;
        this.updateFields({ prices });
    }

    //----------------------------------------------------------------------------------

    render() {
        const { isLoading } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <div className="row">

                <div className="col-md-6">

                    {this.renderMainDetails()}

                </div>

                <div className="col-md-6">

                    {this.renderPhoto()}

                </div>

            </div>

            {this.renderInfoPanel()}

            {/*
            <div className="control-panel">

                {!!product.id &&
                    <button type="button" className="btn btn-secondary" onClick={() => this.confirmDelete()}>
                        <span className="fa-solid fa-times" />{' '}
                        Delete
                    </button>
                }

                <button type="submit" className="btn btn-primary ms-auto">
                    <span className="fa-solid fa-check" />{' '}
                    Save
                </button>

            </div>
            */}

        </>);
    }

    renderMainDetails() {
        const { loginInfo, sku } = this.props;

        return (<>

            {loginInfo.site.useMultipleProductSKUs &&
                <div className="form-group">
                    <label className="form-label">Name</label>
                    <input
                        type="text"
                        className="form-control"
                        value={sku.name || ''}
                        onChange={e => this.updateFields({ name: e.target.value })}
                    />
                </div>
            }

            <div className="form-group">
                <label className="form-label">Product / Gateway ID</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.businessID || ''}
                    onChange={e => this.updateFields({ businessID: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Our SKU</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.ourSKU || ''}
                    onChange={e => this.updateFields({ ourSKU: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">MiA Unique SKU</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.miaUniqueSKU || ''}
                    onChange={e => this.updateFields({ miaUniqueSKU: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Supplier SKU</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.supplierSKU || ''}
                    onChange={e => this.updateFields({ supplierSKU: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Supplier URL</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.supplierURL || ''}
                    onChange={e => this.updateFields({ supplierURL: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Internal Notes</label>
                <ExpandingTextArea
                    className="form-control"
                    value={sku.internalNotes || ''}
                    onChange={e => this.updateFields({ internalNotes: e.target.value })}
                    rows={3}
                />
            </div>

            <div className="form-group">
                <label className="form-label">File Format</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.fileFormat || ''}
                    onChange={e => this.updateFields({ fileFormat: e.target.value })}
                />
            </div>

        </>);
    }

    renderPhoto() {
        const { sku } = this.props;
        const photos = [...sku.photos];
        if (!photos.find(p => p.isNew)) {
            photos.push({
                id: -photos.length,
                isNew: true
            });
        }
        const firstPhotoURL = photos.map(p => p.url).find(url => !!url);

        return (<>

            <div className="form-group">
                <label className="form-label">Photo</label>

                <div className="product-photo">
                    
                    {firstPhotoURL ?
                        <div
                            className="product-photo-inner"
                            style={{
                                backgroundImage: `url(${firstPhotoURL})`
                            }}
                        /> :
                        <div className="no-photo">
                            No photo
                        </div>
                    }

                </div>

            </div>

            <div className="form-group">
                <label className="form-label">Photo URLs</label>
                {photos.map((photo, index) =>
                    <input
                        key={photo.id}
                        type="text"
                        className="form-control"
                        placeholder="Add another photo URL..."
                        value={photo.url || ''}
                        onChange={e => this.updatePhotoFields(index, { url: e.target.value })}
                    />
                )}
            </div>

        </>);
    }

    renderInfoPanel() {
        const { loginInfo } = this.props;

        const tabs = {
            'product-type': {
                name: 'Product Type',
                render: this.renderProductTypeTab.bind(this)
            },
            'print-area': {
                name: 'Print Area',
                render: this.renderPrintAreaTab.bind(this)
            },
            'e-commerce': {
                name: (loginInfo.site.renameECommerceToDescription ? 'Description' : 'E-Commerce'),
                render: this.renderECommerceTab.bind(this)
            },
            'pricing': {
                name: 'Pricing',
                render: this.renderPricingTab.bind(this)
            },
            'templates': {
                name: 'Templates',
                render: this.renderTemplatesTab.bind(this)
            },
            customisationAndImprint: {
                name: 'Customisation & Imprint',
                render: this.renderCustomisationImprintTab.bind(this)
            },
            shipping: {
                name: 'Shipping',
                render: this.renderShippingTab.bind(this)
            },
            competitors: {
                name: 'Competitors',
                render: this.renderCompetitorsTab.bind(this)
            }
        };
        return (
            <div className="info-panel">

                <ul className="nav nav-tabs mb-3">
                    {Object.keys(tabs).map(id => {
                        const tab = tabs[id];
                        const isActive = (this.state.tab == id);
                        return (
                            <li className="nav-item" key={id}>
                                <a className={`nav-link ${isActive ? 'active' : ''}`} aria-current="page" href="#" onClick={e => {
                                    e.preventDefault();
                                    this.setState({ tab: id });
                                }}>
                                    {tab.name}
                                </a>
                            </li>
                        );
                    })}
                </ul>

                {Object.keys(tabs).map(id => {
                    const tab = tabs[id];
                    if (this.state.tab == id && tab.render) {
                        return (
                            <React.Fragment key={id}>
                                {tab.render()}
                            </React.Fragment>
                        );
                    }
                    return null;
                })}

            </div>
        );
    }

    renderProductTypeTab() {
        const { sku } = this.props;
        return (<>

            <div className="form-group">
                <label className="form-label">Print Type</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.printType || ''}
                    onChange={e => this.updateFields({ printType: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Print Locations</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.printLocations || ''}
                    onChange={e => this.updateFields({ printLocations: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Eco</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.eco || ''}
                    onChange={e => this.updateFields({ eco: e.target.value })}
                />
            </div>
            
        </>);
    }

    renderPrintAreaTab() {
        const { sku } = this.props;
        return (<>

            <div className="form-group">
                <label className="form-label">Print Width 1</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.printWidth || ''}
                    onChange={e => this.updateFields({ printWidth: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Print Height 1</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.printHeight || ''}
                    onChange={e => this.updateFields({ printHeight: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Print DPI 1</label>
                <input
                    type="number"
                    className="form-control"
                    value={sku.printDPI || ''}
                    onChange={e => this.updateFields({ printDPI: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Print Width</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.printWidth2 || ''}
                    onChange={e => this.updateFields({ printWidth2: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Print Height 2</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.printHeight2 || ''}
                    onChange={e => this.updateFields({ printHeight2: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Print DPI 2</label>
                <input
                    type="number"
                    className="form-control"
                    value={sku.printDPI2 || ''}
                    onChange={e => this.updateFields({ printDPI2: e.target.value })}
                />
            </div>
        </>);
    }

    renderECommerceTab() {
        const { sku } = this.props;
        return (<>

            <div className="form-group">
                <label className="form-label">Size</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.size || ''}
                    onChange={e => this.updateFields({ size: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Colour</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.colour || ''}
                    onChange={e => this.updateFields({ colour: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Product Weight</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.weight || ''}
                    onChange={e => this.updateFields({ weight: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Product Width</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.width || ''}
                    onChange={e => this.updateFields({ width: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Product Height</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.height || ''}
                    onChange={e => this.updateFields({ height: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Product Length</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.length || ''}
                    onChange={e => this.updateFields({ length: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Materials</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.materials || ''}
                    onChange={e => this.updateFields({ materials: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Description</label>
                <ExpandingTextArea
                    className="form-control"
                    value={sku.description || ''}
                    onChange={e => this.updateFields({ description: e.target.value })}
                />
            </div>

        </>);
    }

    renderPricingTab() {
        const { loginInfo, sku } = this.props;

        return (<>

            <div className="form-group">
                <label className="form-label">Trade</label>
                <CurrencyInput
                    currency={sku.currency}
                    className="form-control"
                    value={sku.tradePrice || ''}
                    onValueChange={values => this.updateFields({ tradePrice: Number(values.value) })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">RRP</label>
                <CurrencyInput
                    currency={sku.currency}
                    className="form-control"
                    value={sku.rrp || ''}
                    onValueChange={values => this.updateFields({ rrp: Number(values.value) })}
                />
            </div>

            {!loginInfo.site.usePricingMatrix &&
                <div className="form-group">
                    <label className="form-label">Cost</label>
                    <CurrencyInput
                        currency={sku.currency}
                        className="form-control"
                        value={sku.cost || ''}
                        onValueChange={values => this.updateFields({ cost: Number(values.value) })}
                    />
                </div>
            }

            <div className="form-group">
                <label className="form-label">Shipping price (Domestic)</label>
                <CurrencyInput
                    currency={sku.currency}
                    className="form-control"
                    value={sku.shippingDomesticPrice || ''}
                    onValueChange={values => this.updateFields({ shippingDomesticPrice: Number(values.value) })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Domestic Shipping Margin</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.domesticShippingMargin || ''}
                    onChange={e => this.updateFields({ domesticShippingMargin: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Shipping price (International)</label>
                <CurrencyInput
                    currency={sku.currency}
                    className="form-control"
                    value={sku.shippingInternationalPrice || ''}
                    onValueChange={values => this.updateFields({ shippingInternationalPrice: Number(values.value) })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">International Shipping Margin</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.internationalShippingMargin || ''}
                    onChange={e => this.updateFields({ internationalShippingMargin: e.target.value })}
                />
            </div>
            {/*
            <div className="form-group">
                <label className="form-label">Shipping (UK)</label>
                <CurrencyInput
                    currency={product.currency}
                    className="form-control"
                    value={product.shippingUKPrice || ''}
                    onValueChange={values => this.updateFields({ shippingUKPrice: Number(values.value) })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Shipping (Europe)</label>
                <CurrencyInput
                    currency={product.currency}
                    className="form-control"
                    value={product.shippingEuropePrice || ''}
                    onValueChange={values => this.updateFields({ shippingEuropePrice: Number(values.value) })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Shipping (USA)</label>
                <CurrencyInput
                    currency={product.currency}
                    className="form-control"
                    value={product.shippingUSAPrice || ''}
                    onValueChange={values => this.updateFields({ shippingUSAPrice: Number(values.value) })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Shipping (Rest of World)</label>
                <CurrencyInput
                    currency={product.currency}
                    className="form-control"
                    value={product.shippingRestOfWorldPrice || ''}
                    onValueChange={values => this.updateFields({ shippingRestOfWorldPrice: Number(values.value) })}
                />
            </div>
            */}

            <div className="form-group">
                <label className="form-label">Delivery Time</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.deliveryTime || ''}
                    onChange={e => this.updateFields({ deliveryTime: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Additional Hit Cost</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.additionalHitCost || ''}
                    onChange={e => this.updateFields({ additionalHitCost: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Additional Hit Trade</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.additionalHitTrade || ''}
                    onChange={e => this.updateFields({ additionalHitTrade : e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Additional Hit Margin</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.additionalHitMargin || ''}
                    onChange={e => this.updateFields({ additionalHitMargin: e.target.value })}
                />
            </div>

            {loginInfo.site.usePricingMatrix &&
                this.renderPricingMatrix()
            }
        </>);
    }

    renderPricingMatrix() {
        const { currencies } = this.state;
        const { sku } = this.props;

        // Create lookup for quantity notes etc
        const quantities = {};
        sku.quantities.forEach(psq => {
            quantities[psq.quantity] = psq;
        });

        // Convert price matrix to a series of rows by quantity
        let pricesByQuantity = {};
        sku.prices.forEach(psp => {
            if (!pricesByQuantity[psp.quantity]) {
                pricesByQuantity[psp.quantity] = {
                    quantity: psp.quantity,
                    currencies: {}
                };
            }
            pricesByQuantity[psp.quantity].currencies[psp.currencyID] = psp.price;
        });
        pricesByQuantity = Object.values(pricesByQuantity);
        pricesByQuantity.push({ isNew: true, id: TextHelpers.getRandomID(), currencies: {} });
        
        return (
            <table className="table table-bordered pricing-matrix-table">
                <thead>
                    <tr>
                        <th className="quantity-col">Quantity</th>
                        {currencies.map(c =>
                            <th key={c.id}>{c.isoCode} ({c.symbol})</th>
                        )}
                        <th className="notes-col">Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {pricesByQuantity.map((priceRow, index) => {
                        const quantityRow = sku.quantities.find(psq => psq.quantity == priceRow.quantity) || { quantity: priceRow.quantity };

                        return (
                            <tr key={index}>
                                <td className="quantity-col">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Quantity..."
                                        key={priceRow.quantity || ''}
                                        defaultValue={priceRow.quantity || ''}
                                        onBlur={e => this.updatePriceFields(priceRow.quantity, null, { quantity: e.target.value })}
                                    />
                                </td>
                                {currencies.map(c => {
                                    const price = priceRow.currencies[c.id] || '';

                                    return (
                                        <td key={c.id}>
                                            {!priceRow.isNew &&
                                                <CurrencyInput
                                                    className="form-control"
                                                    placeholder="Price..."
                                                    currency={c}
                                                    defaultValue={price || ''}
                                                    onValueChange={(values, e) => this.updatePriceFields(priceRow.quantity, c.id, { price: values.value })}
                                                />
                                            }
                                        </td>
                                    );
                                })}
                                <td className="notes-col">
                                    {!priceRow.isNew &&
                                        <input
                                            type="text"
                                            className="form-control"
                                            key={quantityRow.notes || ''}
                                            defaultValue={quantityRow.notes || ''}
                                            onBlur={e => this.updateQuantityFields(quantityRow.quantity, { notes: e.target.value })}
                                        />
                                    }
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    renderTemplatesTab() {
        const { sku } = this.props;
        return (<>

            <div className="form-group">
                <label className="form-label">Mock Up Template</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.mockUpTemplateLink || ''}
                    onChange={e => this.updateFields({ mockUpTemplateLink: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Print Template</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.printTemplateLink || ''}
                    onChange={e => this.updateFields({ printTemplateLink: e.target.value })}
                />
            </div>

        </>);
    }

    renderCustomisationImprintTab() {
        const { sku } = this.props;
        return (<>
            
            <div className="form-group">
                <label className="form-label">Customisation options</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.customisationOptions || ''}
                    onChange={e => this.updateFields({ customisationOptions: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Customisation colours</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.customisationColours || ''}
                    onChange={e => this.updateFields({ customisationColours: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Imprint dimensions</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.imprintDimensions || ''}
                    onChange={e => this.updateFields({ imprintDimensions: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Imprint method</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.imprintMethod || ''}
                    onChange={e => this.updateFields({ imprintMethod: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Imprint locations</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.imprintLocations || ''}
                    onChange={e => this.updateFields({ imprintLocations: e.target.value })}
                />
            </div>

        </>);
    }

    renderShippingTab() {
        const { sku } = this.props;
        return (<>

            <div className="form-group">
                <label className="form-label">Shipping method</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.shippingMethod || ''}
                    onChange={e => this.updateFields({ shippingMethod: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Packaging method</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.packagingMethod || ''}
                    onChange={e => this.updateFields({ packagingMethod: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Package type</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.packageType || ''}
                    onChange={e => this.updateFields({ packageType: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Quantity per carton</label>
                <input
                    type="number"
                    className="form-control"
                    value={sku.quantityPerCarton || ''}
                    onChange={e => this.updateFields({ quantityPerCarton: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Shipping width</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.shippingWidth || ''}
                    onChange={e => this.updateFields({ shippingWidth: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Shipping height</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.shippingHeight || ''}
                    onChange={e => this.updateFields({ shippingHeight: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Shipping length</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.shippingLength || ''}
                    onChange={e => this.updateFields({ shippingLength: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Shipping weight</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.shippingWeight || ''}
                    onChange={e => this.updateFields({ shippingWeight: e.target.value })}
                />
            </div>

        </>);
    }

    renderCompetitorsTab() {
        const { sku } = this.props;
        return (<>

            <div className="form-group">
                <label className="form-label">Printify Pricing</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.printifyPricing || ''}
                    onChange={e => this.updateFields({ printifyPricing: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Printify Domestic Shipping</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.printifyDomesticShipping || ''}
                    onChange={e => this.updateFields({ printifyDomesticShipping: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Printful Pricing</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.printfulPricing || ''}
                    onChange={e => this.updateFields({ printfulPricing: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Printful Domestic Shipping</label>
                <input
                    type="text"
                    className="form-control"
                    value={sku.printfulDomesticShipping || ''}
                    onChange={e => this.updateFields({ printfulDomesticShipping: e.target.value })}
                />
            </div>

        </>);
    }
}

export default EditProductSKU;
