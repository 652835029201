// Libs
import React from 'react';

//  Services
import API from 'API';

// Components
import Loader from 'components/common/Loader';

// Images
import disruptLogoImage from 'images/disrupt-logo.png';
import miaLogoImage from 'images/mia-logo.png';

//-------------------------------------------------------------------------------------------------------------------

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            user: {},
        };
    }

    async submit() {
        const {
            user,
            isRequestAccess
        } = this.state;
        this.setState({ isLoading: true });
        try {
            if (isRequestAccess) {
                await API.call('account/request-access', user);
                this.setState({ isLoading: false, isCompleted: true });
            } else {
                await API.call('account/log-in', user);
                this.props.onLogIn();
            }
        } catch (error) {
            this.setState({
                isLoading: false,
                error
            });
        }
    }

    showRequestAccess() {
        this.setState({
            error: null,
            isRequestAccess: true,
            isCompleted: false
        });
    }

    showLogin() {
        this.setState({
            error: null,
            isRequestAccess: false,
            isCompleted: false
        });
    }

    updateFields(fields) {
        const user = { ...this.state.user };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            user[fieldName] = value;
        }
        this.setState({ user });
    }

    render() {
        const { loginInfo } = this.props;

        let logoImage;
        switch (loginInfo.site.cssClass) {
            case 'disrupt': logoImage = disruptLogoImage; break;
            case 'mia': logoImage = miaLogoImage; break;
        }

        return (<>

            <form className="panel login-panel" onSubmit={e => { e.preventDefault(); this.submit() }}>

                <img src={logoImage} />

                {this.renderInner()}

            </form>

        </>);
    }

    renderInner() {
        const {
            isLoading,
            isRequestAccess
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        else if (isRequestAccess) {
            return this.renderRequestAccess();
        } else {
            return this.renderLogin();
        }
    }

    renderLogin() {
        const {
            user,
            error
        } = this.state;
        const { loginInfo } = this.props;

        return (<>

            {error &&
                <div className="alert alert-danger">
                    {error}
                </div>
            }

            <div className="form-group">
                <label className="form-label">Email</label>
                <input
                    type="email"
                    className="form-control"
                    value={user.email || ''}
                    onChange={e => this.updateFields({ email: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Password</label>
                <input
                    type="password"
                    className="form-control"
                    value={user.password || ''}
                    onChange={e => this.updateFields({ password: e.target.value })}
                />
            </div>

            <button className="btn btn-primary" type="submit">
                Log in
            </button>

            {loginInfo.site.allowRequestAccess &&
                <button className="btn btn-secondary mt-3" type="button" onClick={() => this.showRequestAccess()}>
                    Request access
                </button>
            }
        </>);
    }

    renderRequestAccess() {
        const { user, isCompleted, error } = this.state;

        if (isCompleted) {
            return (<>

                <div className="alert alert-info">
                    Thank you. Your access request has been sent. You will be alerted by email when your request has been approved.
                </div>

                <button className="btn btn-secondary mt-3" type="button" onClick={() => this.showLogin()}>
                    Go back to login page
                </button>

            </>);
        }

        return (<>

            {error &&
                <div className="alert alert-danger">
                    {error}
                </div>
            }

            <div className="form-group">
                <label className="form-label">Full name</label>
                <input
                    type="text"
                    className="form-control"
                    value={user.name || ''}
                    onChange={e => this.updateFields({ name: e.target.value })}
                    autoFocus
                />
            </div>

            <div className="form-group">
                <label className="form-label">Email</label>
                <input
                    type="email"
                    className="form-control"
                    value={user.email || ''}
                    onChange={e => this.updateFields({ email: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Phone number</label>
                <input
                    type="tel"
                    className="form-control"
                    value={user.phoneNumber || ''}
                    onChange={e => this.updateFields({ phoneNumber: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Company</label>
                <input
                    type="text"
                    className="form-control"
                    value={user.company || ''}
                    onChange={e => this.updateFields({ company: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Trade body member</label>
                <select
                    className="form-control"
                    value={user.tradeBody || ''}
                    onChange={e => this.updateFields({ tradeBody: e.target.value })}
                >
                    <option value="">None</option>
                    <option value="ASI">ASI</option>
                    <option value="PSI">PSI</option>
                    <option value="SAGE">SAG</option>
                    <option value="APPA">APPA</option>
                </select>
            </div>

            {!!user.tradeBody &&
                <div className="form-group">
                    <label className="form-label">Trade body number</label>
                    <input
                        type="text"
                        className="form-control"
                        value={user.tradeBodyNumber || ''}
                        onChange={e => this.updateFields({ tradeBodyNumber: e.target.value })}
                    />
                </div>
            }

            <div className="form-group">
                <label className="form-label">Currency</label>
                <select
                    className="form-control"
                    value={user.currencyID || ''}
                    onChange={e => this.updateFields({currencyID: e.target.value})}
                >
                    <option value="">Select currency</option>
                    <option key={1} value={1}>GBP (£)</option>
                    <option key={2} value={2}>EUR (€)</option>
                    <option key={3} value={3}>USD ($)</option>
                    <option key={4} value={4}>AUD ($)</option>
                    <option key={5} value={5}>CAD ($)</option>
                </select>
            </div>

            <div className="form-group">
                <label className="form-label">Password (minimum: 8 characters)</label>
                <input
                    type="password"
                    className="form-control"
                    value={user.password || ''}
                    onChange={e => this.updateFields({password: e.target.value})}
                />
            </div>

            <button className="btn btn-primary" type="submit">
                Request access
            </button>

            <button className="btn btn-secondary mt-3" type="button" onClick={() => this.showLogin()}>
                Go back to login page
            </button>
        </>);
    }
}

export default Login;