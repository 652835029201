import React from 'react';
import { NumericFormat } from 'react-number-format';

class CurrencyInput extends React.Component {

    render() {
        const { currency } = this.props;
        let prefix = '£';
        if (currency) {
            prefix = currency.symbol;
        }
        return (
            <NumericFormat
                prefix={prefix}
                thousandSeparator=","
                {...this.props}
            />
        );
    }

}

export default CurrencyInput;