// Libs
import React from 'react';
import { Link } from 'react-router-dom';

// Services & Helpers
import API from 'API';

// Components
import Loader from 'components/common/Loader';

class ListSupplierCategories extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        this.setState({
            isLoading: true
        });
        const categories = await API.call('supplier-category/list');
        this.setState({
            isLoading: false,
            categories
        });
    }

    view(id) {
        this.props.history.push(`/admin/supplier-category/${id || 0}`);
    }

    render() {
        return (
            <section>

                <h2>Supplier Categories</h2>

                {this.renderInner()}

                <div className="control-panel">

                    <Link to="/admin/supplier-category/0" className="btn btn-primary">
                        <span className="fa-solid fa-plus" />{' '}
                        New Category
                    </Link>

                </div>

            </section>
        );
    }

    renderInner() {
        const {
            isLoading,
            categories
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (
            <table className="supplier-categories-table clickable">
                <thead>
                    <tr>
                        <th className="name-col">Name</th>
                        <th className="num-suppliers-col"># Suppliers</th>
                    </tr>
                </thead>
                <tbody>
                    {categories.map(category =>
                        <tr key={category.id} onClick={() => this.view(category.id)}>
                            <td className="name-col">{category.name}</td>
                            <td className="num-suppliers-col">{category.numSuppliers}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
}

export default ListSupplierCategories;
