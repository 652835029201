// Libs
import React from 'react';

// Services & Helpers
import API from 'API';

// Components
import Loader from 'components/common/Loader';

class EditUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
        window.scrollTo(0, 0);
    }

    async load() {
        const { id } = this.props;
        this.setState({
            isLoading: true
        });
        let user;
        if (parseInt(id)) {
            user = await API.call('user/get/' + (id || 0));
        } else {
            user = {
            };
        }
        const currencies = await API.call('currency/list');
        this.setState({
            isLoading: false,
            user,
            currencies
        });
    }

    updateFields(values) {
        const user = { ...this.state.user };
        for (let fieldName in values) {
            const value = values[fieldName];
            user[fieldName] = value;
        }
        this.setState({ user });
    }

    async save() {
        const { user } = this.state;
        this.setState({ isLoading: true });
        await API.call('user/save', user);
        this.props.history.replace(`/admin/${user.isApproved ? 'approved' : 'pending'}-users`);
    }

    confirmDelete() {
        const confirm = window.confirm('Are you sure you want to delete this user?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        const { user } = this.state;
        this.setState({ isLoading: true });
        await API.call('user/delete/' + user.id);
        this.props.history.replace(`/admin/${user.isApproved ? 'approved' : 'pending'}-users`);
    }

    render() {
        return (
            <section>

                <h2>User</h2>

                {this.renderInner()}

            </section>
        );
    }

    renderInner() {
        const {
            isLoading,
            user,
            currencies
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (

            <form onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                this.save();
            }}>

                <div className="form-group">
                    <label className="form-label">Name</label>
                    <input
                        type="text"
                        className="form-control"
                        value={user.name || ''}
                        onChange={e => this.updateFields({ name: e.target.value })}
                    />
                </div>

                <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                        type="email"
                        className="form-control"
                        value={user.email || ''}
                        onChange={e => this.updateFields({ email: e.target.value })}
                    />
                </div>

                <div className="form-group">
                    <label className="form-label">Phone number</label>
                    <input
                        type="tel"
                        className="form-control"
                        value={user.phoneNumber || ''}
                        onChange={e => this.updateFields({ phoneNumber: e.target.value })}
                    />
                </div>

                <div className="form-group">
                    <label className="form-label">Company</label>
                    <input
                        type="text"
                        className="form-control"
                        value={user.company || ''}
                        onChange={e => this.updateFields({ company: e.target.value })}
                    />
                </div>

                <div className="form-group">
                    <label className="form-label">Trade body member</label>
                    <select
                        className="form-control"
                        value={user.tradeBody || ''}
                        onChange={e => this.updateFields({ tradeBody: e.target.value })}
                    >
                        <option value="">None</option>
                        <option value="ASI">ASI</option>
                        <option value="PSI">PSI</option>
                        <option value="SAGE">SAG</option>
                        <option value="APPA">APPA</option>
                    </select>
                </div>

                {!!user.tradeBody &&
                    <div className="form-group">
                        <label className="form-label">Trade body number</label>
                        <input
                            type="text"
                            className="form-control"
                            value={user.tradeBodyNumber || ''}
                            onChange={e => this.updateFields({ tradeBodyNumber: e.target.value })}
                        />
                    </div>
                }

                <div className="form-group">
                    <label className="form-label">Default currency</label>
                    <select
                        className="form-control"
                        value={user.defaultCurrencyID || ''}
                        onChange={e => this.updateFields({ defaultCurrencyID: e.target.value })}
                    >
                        <option value="">(None)</option>
                        {currencies.map(c =>
                            <option key={c.id} value={c.id}>{c.name}</option>
                        )}
                    </select>
                </div>

                <div className="form-group">
                    <label className="form-label">Rebate (%)</label>
                    <input
                        className="form-control"
                        type="number"
                        value={user.rebatePercentage ? (user.rebatePercentage * 100) : ''}
                        onChange={e => this.updateFields({ rebatePercentage: e.target.value ? Number(e.target.value) / 100 : '' })}
                    />
                </div>

                <div className="form-check mb-3">
                    <input
                        id="isApproved"
                        type="checkbox"
                        className="form-check-input"
                        checked={user.isApproved || false}
                        onChange={e => this.updateFields({ isApproved: e.target.checked })}
                    />
                    <label className="form-check-label" htmlFor="isApproved">
                        User is approved
                    </label>
                </div>

                <div className="form-check mb-3">
                    <input
                        id="isAdmin"
                        type="checkbox"
                        className="form-check-input"
                        checked={user.isAdmin || false}
                        onChange={e => this.updateFields({ isAdmin: e.target.checked })}
                    />
                    <label className="form-check-label" htmlFor="isAdmin">
                        User is an admin
                    </label>
                </div>

                <div className="form-group">
                    <label className="form-label">Change Password</label>
                    <input
                        type="password"
                        className="form-control"
                        value={user.newPassword || ''}
                        onChange={e => this.updateFields({ newPassword: e.target.value })}
                        placeholder="Enter a new password here to change this user's password"
                    />
                </div>

                <div className="control-panel">

                    {!!user.id &&
                        <button type="button" className="btn btn-secondary" onClick={() => this.confirmDelete()}>
                            <span className="fa-solid fa-times" />{' '}
                            Delete
                        </button>
                    }

                    <button type="submit" className="btn btn-primary ms-auto">
                        <span className="fa-solid fa-check" />{' '}
                        Save
                    </button>

                </div>

            </form>

        );
    }
}

export default EditUser;
