// Libs
import React from 'react';
import { Link } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

// Services & Helpers
import API from 'API';
import TextHelpers from 'helpers/TextHelpers';

// Components
import Loader from 'components/common/Loader';
import Money from 'components/common/Money';
import QuoteModal from 'components/product/QuoteModal';

class ViewProduct extends React.Component {

    constructor(props) {
        super(props);

        this.quoteModalRef = React.createRef();

        this.tabs = {
            'product-type': {
                name: () => 'Product Type',
                render: this.renderProductTypeTab.bind(this),
                hasInfo: sku => (
                    sku.printType ||
                    sku.printLocations ||
                    sku.eco
                )
            },
            'print-area': {
                name: () => 'Print Area',
                render: this.renderPrintAreaTab.bind(this),
                hasInfo: sku => (
                    sku.printWidth ||
                    sku.printHeight ||
                    sku.printDPI ||
                    sku.printWidth2 ||
                    sku.printHeight2 ||
                    sku.printDPI2
                )
            },
            'e-commerce': {
                name: () => (this.props.loginInfo.site.renameECommerceToDescription ? 'Description' : 'E-Commerce'),
                render: this.renderECommerceTab.bind(this),
                hasInfo: sku => (
                    sku.weight ||
                    sku.width ||
                    sku.height ||
                    sku.length ||
                    sku.materials ||
                    sku.description
                )
            },
            'pricing': {
                name: () => 'Pricing',
                render: this.renderPricingTab.bind(this),
                hasInfo: sku => (
                    this.props.loginInfo.site.usePricingMatrix ||
                    sku.tradePrice ||
                    sku.rrp ||
                    sku.cost ||
                    sku.shippingDomesticPrice ||
                    sku.shippingInternationalPrice ||
                    sku.shippingInternationalTrade ||
                    sku.shippingUKPrice ||
                    sku.shippingEuropePrice ||
                    sku.shippingUSAPrice ||
                    sku.shippingRestOfWorldPrice ||
                    sku.deliveryTime || 
                    sku.additionalHitCost ||
                    sku.additionalHitTrade ||
                    sku.additionalHitMargin ||
                    sku.domesticShippingMargin ||
                    sku.internationalShippingMargin
                )
            },
            'templates': {
                name: () => 'Templates',
                render: this.renderTemplatesTab.bind(this),
                hasInfo: sku => (
                    sku.mockUpTemplateLink ||
                    sku.printTemplateLink
                )
            },
            'customisation-imprint': {
                name: () => 'Customisation & Imprint',
                render: this.renderCustomisationImprintTab.bind(this),
                hasInfo: sku => (
                    sku.customisationOptions || 
                    sku.customisationColours ||
                    sku.imprintDimensions ||
                    sku.imprintMethod ||
                    sku.imprintLocations
                )
            },
            'shipping': {
                name: () => 'Shipping',
                render: this.renderShippingTab.bind(this),
                hasInfo: sku => (
                    sku.shippingMethod ||
                    sku.packagingMethod ||
                    sku.packageType ||
                    sku.quantityPerCarton || 
                    sku.shippingWidth ||
                    sku.shippingHeight ||
                    sku.shippingLength
                )
            },
            'competitors': {
                name: () => 'Competitors',
                render: this.renderCompetitorsTab.bind(this),
                hasInfo: sku => (
                    sku.printifyPricing ||
                    sku.printifyDomesticShipping ||
                    sku.printfulPricing ||
                    sku.printfulDomesticShipping
                )
            }
        };

        this.state = {
            isLoading: true,
            currentTab: {}
        };
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(oldProps) {
        if (this.props.productSlug != oldProps.productSlug) {
            this.load();
        }
    }

    async load() {
        const { productSlug, supplierSlug } = this.props;
        const { loginInfo } = this.props;

        this.setState({ isLoading: true });
        const supplierReq = API.call('supplier/get', {
            slug: supplierSlug
        });
        const productReq = await API.call('product/get', {
            slug: productSlug
        });
        const product = await productReq;

        // Select first visible tab for each SKU
        const currentTab = {};
        product.skus.forEach(ps => {
            for (let t in this.tabs) {
                if (this.tabs[t].hasInfo(ps)) {
                    currentTab[ps.id] = t;
                    break;
                }
            }
        });

        // Load currencies
        let currencies;
        if (loginInfo.site.usePricingMatrix) {
            currencies = await API.call('currency/list');
            if (loginInfo.user.defaultCurrencyID) {
                currencies = currencies.filter(c => c.id == loginInfo.user.defaultCurrencyID);
            }
        }

        // Update UI
        this.setState({
            isLoading: false,
            supplier: await supplierReq,
            product,
            currencies,
            currentTab
        }, () => {
            window.scroll({ top: 0, left: 0, behavior: 'auto' });
        });
    }

    setTab(skuID, tab) {
        const currentTab = { ...this.state.currentTab };
        currentTab[skuID] = tab;
        this.setState({ currentTab });
    }

    showQuoteModal(sku) {
        this.quoteModalRef.current.open({
            id: sku.id
        });
    }

    render() {
        return (<>
            <section className="product-details">

                {this.renderInner()}

            </section>

            <QuoteModal ref={this.quoteModalRef} {...this.props} />

        </>);
    }

    renderInner() {
        const { isLoading, supplier, product } = this.state;
        const { loginInfo } = this.props;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            <h2>
                {supplier && <>
                    {supplier.category && <>{supplier.category.name} &raquo;{' '}</>}
                    {supplier.name} &raquo;{' '}
                </>}
                {product.name}

                {loginInfo.user.isAdmin &&
                    <Link className="btn btn-sm btn-secondary float-end" to={`/admin/product/${product.id}`}>
                        <span className="fa fa-pencil" />
                    </Link>
                }
            </h2>

            {product.skus.map(ps => this.renderSKU(ps, product.skus.length > 1))}

        </>)
    }

    renderSKU(sku, showName) {
        return (
            <div key={sku.id} className="sku-details">
                {showName && <h3>{sku.name}</h3>}

                {this.renderHeader(sku)}
                {this.renderInfoPanel(sku)}
            </div>
        );
    }

    renderHeader(sku) {
        const { product } = this.state;
        const hasMultiplePhotos = (sku.photos.length > 1);
        const photoDivStyle = {
            //width: '100%'
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '400px'
        };

        return (

            <div className="header">

                {sku.photos.length > 0 &&
                    <div className="photo">

                        <Slide
                            autoplay={false}
                            easing="ease-out"
                            transitionDuration={250}
                            arrows={hasMultiplePhotos}
                            indicators={hasMultiplePhotos}
                            canSwipe={hasMultiplePhotos}
                        >
                            {sku.photos.map((photo, index) => (
                                <div key={index}>
                                    <div style={{ ...photoDivStyle, 'backgroundImage': `url(${photo.url})` }}>
                                        {/*<span>Test caption</span>*/}
                                    </div>
                                </div>
                            ))}
                        </Slide>

                    </div>
                }

                <div className="key-details">

                    <table>
                        <tbody>
                            {!!sku.name &&
                                <tr>
                                    <th>Name</th>
                                    <td>{sku.name}</td>
                                </tr>
                            }
                            <tr>
                                <th>Product ID</th>
                                <td>{sku.id}</td>
                            </tr>
                            {!!product.category &&
                                <tr>
                                    <th>Category</th>
                                    <td>{product.category}</td>
                                </tr>
                            }
                            {!!sku.supplierSKU &&
                                <tr>
                                    <th>Supplier SKU</th>
                                    <td>{sku.supplierSKU}</td>
                                </tr>
                            }
                            {!!sku.supplierURL &&
                                <tr>
                                    <th>Supplier URL</th>
                                    <td>
                                        <a href={sku.supplierURL} target="_blank">View</a>
                                    </td>
                                </tr>
                            }
                            {!!sku.ourSKU &&
                                <tr>
                                    <th>Our SKU</th>
                                    <td>{sku.ourSKU}</td>
                                </tr>
                            }
                            {!!sku.miaUniqueSKU &&
                                <tr>
                                    <th>MiA Unique SKU</th>
                                    <td>{sku.miaUniqueSKU}</td>
                                </tr>
                            }
                        </tbody>
                    </table>

                    <div className="buttons">

                        <button target="_blank" className="btn btn-primary" onClick={() => this.showQuoteModal(sku)}>
                            Generate quote
                        </button>

                    </div>

                </div>

            </div>
        );
    }

    renderInfoPanel(sku) {
        const { currentTab } = this.state;
        const tabs = Object.keys(this.tabs);
        if (tabs.length == 0) return null;

        return (
            <div className="info-panel">

                <ul className="nav nav-tabs">
                    {tabs.map(id => {
                        const tab = this.tabs[id];
                        const isActive = (currentTab[sku.id] == id);
                        if (tab.hasInfo(sku)) {
                            return (
                                <li className="nav-item" key={id}>
                                    <a className={`nav-link ${isActive ? 'active' : ''}`} aria-current="page" href="#" onClick={e => {
                                        e.preventDefault();
                                        this.setTab(sku.id, id);
                                    }}>
                                        {tab.name()}
                                    </a>
                                </li>
                            );
                        }
                    })}
                </ul>

                <div className="info-panel-body">
                    {Object.keys(this.tabs).map(id => {
                        const tab = this.tabs[id];
                        if (currentTab[sku.id] == id && tab.render && tab.hasInfo(sku)) {
                            return (
                                <React.Fragment key={id}>
                                    {tab.render(sku)}
                                </React.Fragment>
                            );
                        }
                        return null;
                    })}
                </div>

            </div>
        );
    }

    renderProductTypeTab(sku) {
        return (
            <table className="">
                <tbody>
                    {!!sku.printType &&
                        <tr>
                            <th>Print Type</th>
                            <td>{sku.printType}</td>
                        </tr>
                    }
                    {!!sku.printLocations &&
                        <tr>
                            <th>Print Locations</th>
                            <td>{sku.printLocations}</td>
                        </tr>
                    }
                    {!!sku.eco &&
                        <tr>
                            <th>Eco</th>
                            <td>{sku.eco}</td>
                        </tr>
                    }
                </tbody>
            </table>
        );
    }

    renderPrintAreaTab(sku) {
        return (
            <table className="">
                <tbody>
                    {!!sku.printWidth &&
                        <tr>
                            <th>Print Width 1</th>
                            <td>{sku.printWidth}</td>
                        </tr>
                    }
                    {!!sku.printHeight &&
                        <tr>
                            <th>Print Height 1</th>
                            <td>{sku.printHeight}</td>
                        </tr>
                    }
                    {!!sku.printDPI &&
                        <tr>
                            <th>Print DPI 1</th>
                            <td>{sku.printDPI}</td>
                        </tr>
                    }
                    {!!sku.printWidth2 &&
                        <tr>
                            <th>Print Width 2</th>
                            <td>{sku.printWidth2}</td>
                        </tr>
                    }
                    {!!sku.printHeight2 &&
                        <tr>
                            <th>Print Height 2</th>
                            <td>{sku.printHeight2}</td>
                        </tr>
                    }
                    {!!sku.printDPI2 &&
                        <tr>
                            <th>Print DPI 2</th>
                            <td>{sku.printDPI2}</td>
                        </tr>
                    }
                </tbody>
            </table>
        );
    }

    renderECommerceTab(sku) {
        return (
            <table className="product-details-table">
                <tbody>
                    {!!sku.size &&
                        <tr>
                            <th>Size</th>
                            <td>{sku.size}</td>
                        </tr>
                    }
                    {!!sku.colour &&
                        <tr>
                            <th>Colour</th>
                            <td>{sku.colour}</td>
                        </tr>
                    }
                    {!!sku.weight &&
                        <tr>
                            <th>Product Weight</th>
                            <td>{sku.weight}</td>
                        </tr>
                    }
                    {!!sku.width &&
                        <tr>
                            <th>Product Width</th>
                            <td>{sku.width}</td>
                        </tr>
                    }
                    {!!sku.height &&
                        <tr>
                            <th>Product Height</th>
                            <td>{sku.height}</td>
                        </tr>
                    }
                    {!!sku.length &&
                        <tr>
                            <th>Product Length</th>
                            <td>{sku.length}</td>
                        </tr>
                    }
                    {!!sku.materials &&
                        <tr>
                            <th>Materials</th>
                            <td>{sku.materials}</td>
                        </tr>
                    }
                    {!!sku.description &&
                        <tr>
                            <th>Product Description</th>
                            <td>{TextHelpers.splitIntoRows(sku.description)}</td>
                        </tr>
                    }
                </tbody>
            </table>
        );
    }

    renderPricingTab(sku) {
        const { product } = this.state;
        const { loginInfo } = this.props;

        return (<>
            {loginInfo.site.usePricingMatrix &&
                this.renderPricingMatrix(sku)
            }

            <table className="product-details-table">
                <tbody>
                    {!!sku.tradePrice &&
                        <tr>
                            <th>Trade</th>
                            <td><Money currency={product.currency} amount={sku.tradePrice || null} /></td>
                        </tr>
                    }
                    {!!sku.rrp &&
                        <tr>
                            <th>RRP</th>
                            <td><Money currency={product.currency} amount={sku.rrp || null} /></td>
                        </tr>
                    }
                    {!!sku.cost &&
                        <tr>
                            <th>Cost</th>
                            <td><Money currency={product.currency} amount={sku.cost || null} /></td>
                        </tr>
                    }
                    {!!sku.shippingDomesticPrice &&
                        <tr>
                            <th>Shipping (Domestic)</th>
                            <td><Money currency={product.currency} amount={sku.shippingDomesticPrice || null} /></td>
                        </tr>
                    }
                    {!!sku.domesticShippingMargin &&
                        <tr>
                            <th>Domestic Shipping Margin</th>
                            <td>{sku.domesticShippingMargin}</td>
                        </tr>
                    }
                    {!!sku.shippingInternationalPrice &&
                        <tr>
                            <th>Shipping (International)</th>
                            <td><Money currency={product.currency} amount={sku.shippingInternationalPrice || null} /></td>
                        </tr>
                    }
                    {!!sku.internationalShippingMargin &&
                        <tr>
                            <th>International Shipping Margin</th>
                            <td>{sku.internationalShippingMargin}</td>
                        </tr>
                    }
                    {!!sku.shippingInternationalTrade &&
                        <tr>
                            <th>Shipping (International, Trade)</th>
                            <td><Money currency={product.currency} amount={sku.shippingInternationalTrade || null} /></td>
                        </tr>
                    }
                    {!!sku.shippingUKPrice &&
                        <tr>
                            <th>Shipping (UK)</th>
                            <td><Money currency={product.currency} amount={sku.shippingUKPrice || null} /></td>
                        </tr>
                    }
                    {!!sku.shippingEuropePrice &&
                        <tr>
                            <th>Shipping (Europe)</th>
                            <td><Money currency={product.currency} amount={sku.shippingEuropePrice || null} /></td>
                        </tr>
                    }
                    {!!sku.shippingUSAPrice &&
                        <tr>
                            <th>Shipping (USA)</th>
                            <td><Money currency={product.currency} amount={sku.shippingUSAPrice || null} /></td>
                        </tr>
                    }
                    {!!sku.shippingRestOfWorldPrice &&
                        <tr>
                            <th>Shipping (Rest of World)</th>
                            <td><Money currency={product.currency} amount={sku.shippingRestOfWorldPrice || null} /></td>
                        </tr>
                    }
                    {!!sku.deliveryTime &&
                        <tr>
                            <th>Delivery Time</th>
                            <td>{sku.deliveryTime}</td>
                        </tr>
                    }
                    {!!sku.additionalHitCost &&
                        <tr>
                            <th>Additional Hit Cost</th>
                            <td>{sku.additionalHitCost}</td>
                        </tr>
                    }
                    {!!sku.additionalHitTrade &&
                        <tr>
                            <th>Additional Hit Trade</th>
                            <td>{sku.additionalHitTrade}</td>
                        </tr>
                    }
                    {!!sku.additionalHitMargin &&
                        <tr>
                            <th>Additional Hit Margin</th>
                            <td>{sku.additionalHitMargin}</td>
                        </tr>
                    }
                </tbody>
            </table>
        </>);
    }

    renderPricingMatrix(sku) {
        // Create lookup for quantity notes etc
        const quantities = {};
        sku.quantities.forEach(psq => {
            quantities[psq.quantity] = psq;
        });

        // Convert price matrix to a series of rows by quantity
        let pricesByQuantity = {};
        sku.prices.forEach(pp => {
            if (!pricesByQuantity[pp.quantity]) {
                pricesByQuantity[pp.quantity] = {
                    quantity: pp.quantity,
                    notes: pp.notes,
                    currencies: {}
                };
            }
            pricesByQuantity[pp.quantity].currencies[pp.currencyID] = pp.price;
        });
        pricesByQuantity = Object.values(pricesByQuantity);

        if (pricesByQuantity.length == 0) {
            return null;
        }

        // Only show currencies with any numbers in them
        const currencies = this.state.currencies.filter(c =>
            Object.keys(pricesByQuantity).some(pbq => {
                return Number(pricesByQuantity[pbq].currencies[c.id] || 0) != 0;
            })
        );

        return (
            <table className="table table-bordered pricing-matrix-table">
                <thead>
                    <tr>
                        <th rowSpan={2}>Quantity</th>
                        <th colSpan={currencies.length}>(Per Unit - Excluding GST/VAT)</th>
                        <th rowSpan={2}></th>
                    </tr>
                    <tr>
                        {currencies.map(c =>
                            <th key={c.id}>{c.isoCode} ({c.symbol})</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {pricesByQuantity.map((priceRow, index) => {
                        const quantityRow = quantities[priceRow.quantity] || {};
                        return (
                            <tr key={index}>
                                <td>{priceRow.quantity}</td>
                                {currencies.map(c => {
                                    const price = priceRow.currencies[c.id] || '';
                                    return (
                                        <td key={c.id}>
                                            <Money currency={c} amount={price || null} />
                                        </td>
                                    );
                                })}
                                <td>
                                    {quantityRow.notes}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    renderTemplatesTab(sku) {
        return (
            <table className="product-details-table">
                <tbody>
                    {!!sku.mockUpTemplateLink &&
                        <tr>
                            <th>Mock Up Template</th>
                            <td>
                                <a href={sku.mockUpTemplateLink} target="_blank">
                                    Download
                                </a>
                            </td>
                        </tr>
                    }
                    {!!sku.printTemplateLink &&
                        <tr>
                            <th>Print Template</th>
                            <td>
                                <a href={sku.printTemplateLink} target="_blank">
                                    Download
                                </a>
                            </td>
                        </tr>
                    }
                    {!!sku.fileFormat &&
                        <tr>
                            <th>File Format</th>
                            <td>
                                {sku.fileFormat}
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        );
    }

    renderCustomisationImprintTab(sku) {
        return (
            <table className="product-details-table">
                <tbody>
                    {!!sku.customisationOptions &&
                        <tr>
                            <th>Customisation Options</th>
                            <td>{sku.customisationOptions}</td>
                        </tr>
                    }
                    {!!sku.customisationColours &&
                        <tr>
                            <th>Customisation Colours</th>
                            <td>{sku.customisationColours}</td>
                        </tr>
                    }
                    {!!sku.imprintDimensions &&
                        <tr>
                            <th>Imprint Dimensions</th>
                            <td>{sku.imprintDimensions}</td>
                        </tr>
                    }
                    {!!sku.imprintMethod &&
                        <tr>
                            <th>Imprint Method</th>
                            <td>{sku.imprintMethod}</td>
                        </tr>
                    }
                    {!!sku.imprintLocations &&
                        <tr>
                            <th>Imprint Locations</th>
                            <td>{sku.imprintLocations}</td>
                        </tr>
                    }
                </tbody>
            </table>
        );
    }

    renderShippingTab(sku) {
        return (
            <table className="product-details-table">
                <tbody>
                    {!!sku.shippingMethod &&
                        <tr>
                            <th>Shipping Method</th>
                            <td>{sku.shippingMethod}</td>
                        </tr>
                    }
                    {!!sku.packagingMethod &&
                        <tr>
                            <th>Packaging Method</th>
                            <td>{sku.packagingMethod}</td>
                        </tr>
                    }
                    {!!sku.packageType &&
                        <tr>
                            <th>Package Type</th>
                            <td>{sku.packageType}</td>
                        </tr>
                    }
                    {!!sku.quantityPerCarton &&
                        <tr>
                            <th>Quantity Per Carton</th>
                            <td>{sku.quantityPerCarton}</td>
                        </tr>
                    }
                    {!!sku.shippingWidth &&
                        <tr>
                            <th>Shipping Width</th>
                            <td>{sku.shippingWidth}</td>
                        </tr>
                    }
                    {!!sku.shippingHeight &&
                        <tr>
                            <th>Shipping Height</th>
                            <td>{sku.shippingHeight}</td>
                        </tr>
                    }
                    {!!sku.shippingLength &&
                        <tr>
                            <th>Shipping Length</th>
                            <td>{sku.shippingLength}</td>
                        </tr>
                    }
                    {!!sku.shippingWeight &&
                        <tr>
                            <th>Shipping Weight</th>
                            <td>{sku.shippingWeight}</td>
                        </tr>
                    }
                </tbody>
            </table>
        );
    }

    renderCompetitorsTab(sku) {
        return (
            <table className="product-details-table">
                <tbody>
                    {!!sku.printifyPricing &&
                        <tr>
                            <th>Printify Pricing</th>
                            <td>{sku.printifyPricing}</td>
                        </tr>
                    }
                    {!!sku.printifyDomesticShipping &&
                        <tr>
                            <th>Printify Domestic Shipping</th>
                            <td>{sku.printifyDomesticShipping}</td>
                        </tr>
                    }
                    {!!sku.printfulPricing &&
                        <tr>
                            <th>Printful Pricing</th>
                            <td>{sku.printfulPricing}</td>
                        </tr>
                    }
                    {!!sku.printfulDomesticShipping &&
                        <tr>
                            <th>Printful Domestic Shipping</th>
                            <td>{sku.printfulDomesticShipping}</td>
                        </tr>
                    }
                </tbody>
            </table>
        );
    }

}

export default ViewProduct;
