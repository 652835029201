// Libs
import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";

// Services
import API from 'API';
import DataHelpers from 'helpers/DataHelpers';

// Components
import Loader from 'components/common/Loader';
import SearchBox from 'components/common/SearchBox';

// Images
import disruptLogoImage from 'images/disrupt-logo.png';
import miaLogoImage from 'images/mia-logo.png';

//-------------------------------------------------------------------------------------------------------------------

class NavMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const exportTemplates = await API.call('export-template/list');
        this.setState({
            exportTemplates
        });
    }

    async export(exportTemplateID) {
        if (this.state.isExporting) return;
        this.setState({ isExporting: true });
        const requestID = `${Math.random()}`.replace('0.', '');
        window.location = `/api/export?exportTemplateID=${exportTemplateID}&requestID=${requestID}`;
        const checkDoneInterval = setInterval(() => {
            const exportRequestID = DataHelpers.getCookie('export-request-id');
            if (exportRequestID) {
                document.cookie = 'export-request-id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                this.setState({ isExporting: false });
                clearInterval(checkDoneInterval);
            }
        }, 100);
    }

    async confirmLogOut() {
        const confirm = window.confirm('Are you sure you want to log out?');
        if (confirm) {
            await API.call('account/log-out');
            if (window.location.pathname == '/') {
                window.location.reload();
            } else {
                window.location = '/';
            }
        }
    }

    render() {
        const { exportTemplates, isExporting } = this.state;
        const { loginInfo } = this.props;
        let logoImage;
        switch (loginInfo.site.cssClass) {
            case 'disrupt': logoImage = disruptLogoImage; break;
            case 'mia': logoImage = miaLogoImage; break;
        }

        return (
            <nav className="top-nav">

                <div className="container">

                    <div className="logo">

                        <a href="/">
                            
                            <img src={logoImage} />

                        </a>

                    </div>

                    <div className="search-container">

                        <SearchBox
                            placeholder="Product Name or SKU"
                            search={async (query, setResults) => {
                                this.nonce = `${Math.random()}`;
                                const { results, nonce } = await API.call('search', { query, nonce: this.nonce });
                                if (nonce == this.nonce) {
                                    setResults(results);
                                }
                            }}
                            onClickResult={result => {
                                this.props.history.push(`/browse/${result.supplierCategorySlug || '_'}/${result.supplierSlug || '_'}/${result.slug}`);
                            }}
                        />

                    </div>

                    <div className="controls">

                        {loginInfo.user.isAdmin &&
                            <Link to="/admin/products" className="btn">
                                <span className="fa-solid fa-screwdriver-wrench" /><br />
                                Admin
                            </Link>
                        }

                        {exportTemplates && exportTemplates.length > 0 &&
                            <div className="dropdown">
                                <button className="btn" data-bs-toggle="dropdown">
                                    {isExporting ?
                                        <Loader isInline /> :
                                        <span className="fa-solid fa-file-export" />
                                    }
                                    <br />
                                    Export
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    {exportTemplates.map(et =>
                                        <li key={et.id}>
                                            <a className="dropdown-item" href="#" onClick={e => {
                                                e.preventDefault();
                                                this.export(et.id);
                                            }}>
                                                {et.name}
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        }

                        {loginInfo.user.isAdmin &&
                            <Link className="btn" to="/admin/import-products">
                                <span className="fa-solid fa-file-import" /><br />
                                Import
                            </Link>
                        }

                        <button className="btn" onClick={() => this.confirmLogOut()}>
                            <span className="fa-solid fa-right-from-bracket" /><br />
                            Log out
                        </button>

                    </div>

                </div>

            </nav>
        );
    }

    //renderHighlightedField(searchResult, field, friendlyName, className, fallback, extraContent) {
    //    let value = searchResult.highlighted[field];
    //    if (value) {
    //        return (
    //            <div className={'sr-field ' + (className || '')}>
    //                {friendlyName ? friendlyName + ': ' : ''}
    //                {reactStringReplace(value, /\*([^\\*]*)\*/g, (match, index) => <em key={index}>{match}</em>)}
    //                {extraContent}
    //            </div>
    //        );
    //    }
    //    return fallback || '';
    //}
    
}

export default withRouter(NavMenu);