// Libs
import React from 'react';
import { Link } from 'react-router-dom';

// Services & Helpers
import API from 'API';

// Components
import Loader from 'components/common/Loader';
import PageList from 'components/common/PageList';

class ListProducts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            page: 0
        };
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(oldProps) {
        if (this.props.page != oldProps.page) {
            this.load();
        }
    }

    async load() {
        const page = this.props.page || 0;
        this.setState({
            isLoading: true
        });
        const { products, numPages } = await API.call('product/list', {
            page
        });
        this.setState({
            isLoading: false,
            products,
            numPages
        });
    }

    view(id) {
        this.props.history.push(`/admin/product/${id || 0}`);
    }

    render() {
        return (
            <section>

                <h2>Products</h2>

                {this.renderInner()}

                <div className="control-panel">

                    <Link to="/admin/product/0" className="btn btn-primary">
                        <span className="fa-solid fa-plus" />{' '}
                        New Product
                    </Link>

                </div>

            </section>
        );
    }

    renderInner() {
        const {
            isLoading,
            products,
            numPages
        } = this.state;
        const page = this.props.page || 0;

        if (isLoading) {
            return (<Loader />);
        }

        // Build page list
        const pages = [];
        for (let i = 0; i < numPages; i++) {
            pages.push({
                id: i,
                label: `${i + 1}`
            });
        }

        const pageList = (
            <PageList
                pages={pages}
                page={page}
                getURL={p => `/admin/products/${p}`}
            />
        );

        return (<>

            {pageList}

            <table className="products-table clickable">
                <thead>
                    <tr>
                        <th className="name-col">Name</th>
                        <th className="supplier-col">Supplier</th>
                        <th className="skus-col">SKUs</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map(product =>
                        <tr key={product.id} onClick={() => this.view(product.id)}>
                            <td className="name-col">
                                {product.name}
                            </td>
                            <td className="supplier-col">
                                {product.supplier.name}
                            </td>
                            <td className="skus-col">
                                {product.skus.map(ps => ps.name ?
                                    ps.name + (ps.ourSKU ? ` (${ps.ourSKU})` : '') :
                                    (ps.ourSKU || '')
                                ).join(', ')}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            {pageList}

        </>);
    }
}

export default ListProducts;
