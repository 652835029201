import React, { Component } from 'react';

//-------------------------------------------------------------------------------------------------------------------

class UploadButton extends Component {

    render() {
        return (
            <label className={this.props.className}>
                <input type="file" accept={this.props.accept} multiple={this.props.multiple} onChange={e => {
                    if (e.target.files && e.target.files.length > 0) {
                        if (this.props.multiple) {
                            this.props.onUpload(e.target.files);
                        } else {
                            this.props.onUpload(e.target.files[0]);
                        }
                        if (this.props.resetOnUpload) {
                            e.target.value = '';
                        }
                    }
                }} />
                {this.props.children}
            </label>
        );
    }
};

UploadButton.defaultProps = {
    className: 'button upload-button',
    accept: 'image/*',
    resetOnUpload: true,
    multiple: false,
    onUpload: ((file) => console.log('onUpload not specified for <UploadButton>'))
}

export default UploadButton;
