export default class DataHelpers {

    static sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static mergeIn(dest, source, fieldNames, emptyOnly) {
        dest = { ...dest };
        if (!fieldNames) {
            fieldNames = [];
            for (var prop in source) {
                if (source.hasOwnProperty(prop)) {
                    fieldNames.push(prop);
                }
            }
        }
        for (var i = 0; i < fieldNames.length; i++) {
            const fieldName = fieldNames[i];
            if ((!dest[fieldName] && source[fieldName]) || !emptyOnly) {
                dest[fieldName] = source[fieldName];
            }
        }
        return dest;
    }

    static groupBy(list, fnGetKey) {
        const results = {};
        list.forEach(item => {
            const key = fnGetKey(item);
            if (!results[key]) {
                results[key] = [];
            }
            results[key].push(item);
        });
        return results;
    }

    static isFunction(functionToCheck) {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }

    static findLastIndex(array, predicate) {
        let l = array.length;
        while (l--) {
            if (predicate(array[l], l, array))
                return l;
        }
        return -1;
    }

    static getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        else return '';
    }
}