import { Component } from 'react';
import PropTypes from 'prop-types';

// Services & Helpers
import TextHelpers from 'helpers/TextHelpers';

//-------------------------------------------------------------------------------------------------------------------

class Money extends Component {

    static propTypes = {
        amount: PropTypes.number,
        currencyOnly: PropTypes.bool,
        blankIfZero: PropTypes.bool,
        plusIfPositive: PropTypes.bool
    };
    
    static defaultProps = {
        amount: 0,
        currencyOnly: false
    };

    render() {
        const { currency } = this.props;
        const amount = this.props.amount || 0;
        if (this.props.blankIfZero && amount == 0) {
            return '';
        }
        const formatted = TextHelpers.formatCurrency(amount, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            currency: (currency ? currency.isoCode : 'GBP')
        });
        if (this.props.plusIfPositive && amount > 0) {
            return '+' + formatted;
        }
        return formatted;
    }
}
export default Money;