// Libs
import React from 'react';

// Services & Helpers
import API from 'API';

// Components
import Loader from 'components/common/Loader';
import EditProductSKU from 'components/admin/EditProductSKU';

class EditProduct extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            skuIndex: 0
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { id, loginInfo } = this.props;
        this.setState({
            isLoading: true
        });
        let product;
        const suppliers = await API.call('supplier/list');
        if (parseInt(id)) {
            product = await API.call('product/get', {
                id: (id || 0)
            });
        } else {
            product = {
                skus: []
            };
        }
        let currencies;
        if (loginInfo.site.usePricingMatrix) {
            currencies = await API.call('currency/list');
        }
        if (product.skus.length == 0) {
            product.skus.push({ index: 0, photos: [], prices: [], quantities: [] });
        }
        for (let i = 0; i < product.skus.length; i++) {
            product.skus[i].index = i;
        }

        this.setState({
            isLoading: false,
            product,
            suppliers,
            currencies
        });
    }

    updateFields(values, callback) {
        const product = { ...this.state.product };
        for (let fieldName in values) {
            const value = values[fieldName];
            product[fieldName] = value;
        }
        this.setState({
            product
        }, () => {
            if (callback) {
                callback(product);
            }
        });
    }

    save() {
        return new Promise(async (resolve) => {
            const { product } = this.state;
            this.setState({ isLoading: true });
            const { id } = await API.call('product/save', product);
            this.updateFields({ id }, resolve);
        });
    }

    async saveAndClose() {
        await this.save();
        this.props.history.replace('/admin/products');
    }

    async saveAndView() {
        await this.save();
        const product = await API.call('product/get', { id: this.state.product.id });
        let supplierSlug = '_', supplierCategorySlug = '_';
        if (product.supplier) {
            supplierSlug = product.supplier.slug;
            if (product.supplier.category) {
                supplierCategorySlug = product.supplier.category.slug;
            }
        }
        const url = `/browse/${supplierCategorySlug}/${supplierSlug}/${product.slug}`;
        this.props.history.replace(url);
    }

    confirmDelete() {
        const confirm = window.confirm('Are you sure you want to delete this product?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        this.setState({ isLoading: true });
        await API.call('product/delete/' + this.state.product.id);
        this.props.history.replace('/admin/products');
    }

    updateSKUFields(fields, callback) {
        const { product, skuIndex } = this.state;
        const skus = [...product.skus];
        const sku = { ...skus[skuIndex] };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            sku[fieldName] = value;
        }
        sku.isChanged = true;
        skus[skuIndex] = sku;
        this.updateFields({ skus }, callback);
    }

    addSKU() {
        const skus = [...this.state.product.skus];
        skus.push({
            index: skus.length,
            photos: [],
            prices: [],
            quantities: []
        });
        this.updateFields({
            skus
        }, () => {
            this.setState({ skuIndex: skus.length - 1 });
        });
    }

    confirmDeleteSKU() {
        const confirm = window.confirm('Are you sure you want to delete this product SKU?');
        if (confirm) {
            let { skuIndex } = this.state;
            this.setState({ isLoading: true });
            this.updateSKUFields({
                isDeleted: true
            }, (product) => {
                skuIndex = 0;
                while (product.skus[skuIndex].isDeleted) {
                    skuIndex++;
                }
                this.setState({ isLoading: false, skuIndex });
            });
        }
    }

    //----------------------------------------------------------------------------------

    render() {
        return (
            <section className="edit-product">

                <h2>Product</h2>

                {this.renderInner()}

            </section>
        );
    }

    renderInner() {
        const {
            isLoading,
            product
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (

            <form onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                this.saveAndClose();
            }}>

                {this.renderMainDetails()}

                {this.renderSKUs()}

                <div className="control-panel">

                    {!!product.id &&
                        <button type="button" className="btn btn-secondary" onClick={() => this.confirmDelete()}>
                            <span className="fa-solid fa-times" />{' '}
                            Delete Product
                        </button>
                    }

                    <button type="type" className="btn btn-secondary ms-auto" onClick={e => {
                        e.preventDefault();
                        this.saveAndView();
                    }}>
                        <span className="fa-solid fa-eye" />{' '}
                        Save & View
                    </button>

                    <button type="submit" className="btn btn-primary ms-auto">
                        <span className="fa-solid fa-check" />{' '}
                        Save & Close
                    </button>

                </div>

            </form>

        );
    }

    renderMainDetails() {
        const {
            product,
            suppliers
        } = this.state;

        return (<>

            <div className="form-group">
                <label className="form-label">Name</label>
                <input
                    type="text"
                    className="form-control"
                    value={product.name || ''}
                    onChange={e => this.updateFields({ name: e.target.value })}
                />
            </div>

            <div className="row">

                <div className="col-md-6">

                    {suppliers.length > 0 &&
                        <div className="form-group">
                            <label className="form-label">Supplier</label>
                            <select
                                className="form-control"
                                value={product.supplierID || ''}
                                onChange={e => this.updateFields({ supplierID: e.target.value })}
                            >
                                <option value="">(None)</option>
                                {suppliers.map(supplier =>
                                    <option key={supplier.id} value={supplier.id}>
                                        {supplier.name}
                                    </option>
                                )}
                            </select>
                        </div>
                    }

                </div>

                <div className="col-md-6">
                    
                    <div className="form-group">
                        <label className="form-label">Category</label>
                        <input
                            type="text"
                            className="form-control"
                            value={product.category || ''}
                            onChange={e => this.updateFields({ category: e.target.value })}
                        />
                    </div>

                </div>

            </div>

            {/*
            <div className="form-group">
                <label className="form-label">Internal Notes</label>
                <ExpandingTextArea
                    className="form-control"
                    value={product.internalNotes || ''}
                    onChange={e => this.updateFields({ internalNotes: e.target.value })}
                    rows={3}
                />
            </div>
            */}

        </>);
    }

    renderSKUs() {
        const { loginInfo } = this.props;
        const { product, skuIndex } = this.state;
        const sku = product.skus[skuIndex];
        let numSKUs = 0;
        product.skus.forEach(ps => {
            if (!ps.isDeleted) numSKUs++;
        });

        return (<>

            {loginInfo.site.useMultipleProductSKUs &&
                <ul className="nav nav-pills sku-list">
                    {product.skus.map(ps => {
                        if (ps.isDeleted) {
                            return null;
                        }
                        return (
                            <li key={ps.index} className="nav-item">
                                <a className={`nav-link ${ps.index == skuIndex ? 'active' : ''}`} href="#" onClick={e => {
                                    e.preventDefault();
                                    this.setState({ skuIndex: ps.index });
                                }}>
                                    {ps.name || '(New)'}
                                </a>
                            </li>
                        );
                    })}
                    <li className="nav-item">
                        <a className="nav-link" href="#" onClick={e => {
                            e.preventDefault();
                            this.addSKU();
                        }}>
                            <span className="fa-solid fa-plus" />{' '}
                            New
                        </a>
                    </li>
                    {numSKUs > 1 &&
                        <li className="nav-item ms-auto">
                            <a className="nav-link" href="#" onClick={e => {
                                e.preventDefault();
                                this.confirmDeleteSKU();
                            }}>
                                <span className="fa-solid fa-times" />{' '}
                                Delete
                            </a>
                        </li>
                    }
                </ul>
            }

            <EditProductSKU
                loginInfo={loginInfo}
                sku={sku}
                onUpdate={values => this.updateSKUFields(values)}
            />

        </>);
    }

}

export default EditProduct;
