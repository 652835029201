// Libs
import React from 'react';

// Services
import API from 'API';

// Components
import Loader from 'components/common/Loader';
import ProductsTable from 'components/product/ProductsTable';

class ViewSupplier extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { supplierSlug } = this.props;
        this.setState({ isLoading: true });
        const supplierReq = API.call('supplier/get', {
            slug: supplierSlug
        });
        const productsReq = await API.call('product/list', {
            supplierSlug
        });
        const supplier = await supplierReq;
        const productsResult = await productsReq;
        this.setState({
            isLoading: false,
            supplier,
            products: productsResult.products
        });
    }

    render() {
        return (
            <section>                
                {this.renderInner()}
            </section>
        );
    }

    renderInner() {
        const { isLoading, products, supplier } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <h2>
                {supplier.category.name} &raquo;
                {' '}{supplier.name}
            </h2>

            <ProductsTable
                products={products}
                onClick={product => {
                    const url = `/browse/${product.supplier.category.slug}/${product.supplier.slug}/${product.slug}`;
                    this.props.history.push(url);
                }}
            />
        </>);
    }
}

export default ViewSupplier;
