// Libs
import React from 'react';

// Components
import Money from 'components/common/Money';

class ProductsTable extends React.Component {

    render() {
        const { products, onClick } = this.props;

        return (

            <table className="products-table clickable">
                <thead>
                    <tr>
                        <th className="id-col">Product ID</th>
                        <th className="name-col">Name</th>
                        <th className="our-sku-col">Our SKU(s)</th>
                        <th className="supplier-sku-col">Supplier SKU(s)</th>
                        {/*<th className="supplier-col">Supplier</th>*/}
                        <th className="trade-price-col">Trade Price</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map(product =>
                        <tr
                            key={product.id}
                            onClick={() => onClick(product)}
                        >
                            <td className="id-col">
                                {product.id}
                            </td>
                            <td className="name-col">
                                {product.name}
                            </td>
                            <td className="our-sku-col">
                                {product.skus.map(ps => ps.ourSKU).join(', ')}
                            </td>
                            <td className="supplier-sku-col">
                                {product.skus.map(ps => ps.supplierSKU).join(', ')}
                            </td>
                            {/*
                            <td className="supplier-col">
                                {product.supplier.name}
                            </td>
                            */}
                            <td className="trade-price-col">
                                {this.renderTradePrice(product)}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    renderTradePrice(product) {
        let lowest = 99999999;
        let highest = -1;
        let anySKUs = false;
        product.skus.forEach(ps => {
            if (ps.tradePrice) {
                anySKUs = true;
                if (ps.tradePrice < lowest) lowest = ps.tradePrice;
                if (ps.tradePrice > highest) highest = ps.tradePrice;
            }
        });
        if (!anySKUs) return null;

        return (<>
            <Money
                currency={product.currency}
                amount={lowest}
            />
            {highest != lowest && <>
                {' - '}
                <Money
                    currency={product.currency}
                    amount={highest}
                />
            </>}
        </>);
    }
}

export default ProductsTable;